<div class="kalenderHeader">
  <div class="kalenderHeaderDatum">
    <button mat-icon-button (click)="previousClicked('month')">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <strong class="kalenderHeaderDatumLabel">{{periodLabel()}}</strong>
    <button mat-icon-button (click)="nextClicked('month')">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>
<!--  <div class="kalenderHeaderErneuern">-->
<!--    <button mat-icon-button (click)="erneuern()">-->
<!--      <mat-icon id="kalenderReset">refresh</mat-icon>-->
<!--    </button>-->
<!--  </div>-->
</div>

