<div id="profile">
  <div id="profilNeu">
    <mat-card>
      <div id="profilNeuKarte">
        <ng-template #loginComponent></ng-template>
        <button mat-raised-button (click)="renderLoginComponent()" color="secondary">
          <span>Neuen Benutzer hinzufügen</span>
        </button>
      </div>
    </mat-card>
  </div>
  <div id="profileContent">
    <mat-accordion>
      <mat-expansion-panel *ngFor="let profil of profils">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-checkbox *ngIf="!profil.order4NewPw" [checked]="profil.kundeId == aktives_profil" (change)="profil_wechseln($event, profil)"></mat-checkbox>
            <div class="profilDetails">
              <strong>{{ profil.essensteilnehmer.vorname }}</strong>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="profilWeitereInforamtionen">
          <strong>Benutzerinformationen</strong>
          <div class="profilWeitereInforamtionenItem">
            <span>Kundennummer:</span>
            <span>{{ profil.kundenNr }}</span>
          </div>
          <div class="profilWeitereInforamtionenItem" *ngIf="profil.einrichtung.nameEinrichtung">
            <span>Einrichtung:</span>
            <span>{{ profil.einrichtung.nameEinrichtung }}</span>
          </div>
          <div class="profilWeitereInforamtionenItem" *ngIf="profil.essensteilnehmer.eMail">
            <span>Email:</span>
            <span>{{ profil.essensteilnehmer.eMail }}</span>
          </div>

          <div class="profil-option">
            <barcodeKundennummer *ngIf="environment.mandant_id == '111374-zcatering'"></barcodeKundennummer>
            <button id="benutzerBearbeiten" mat-flat-button (click)="link(profil)" [disabled]="profil.kundeId !== aktives_profil">
              <span>Benutzer einsehen & bearbeiten</span>
            </button>
            <button id="benutzerEntfernen" mat-flat-button (click)="logout(profil.kundeId)">
              <span>Benutzer aus der App ausloggen</span>
            </button>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
