import {Component, ElementRef, Inject, OnInit, QueryList, ViewChildren} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {MenuVorauswahlService} from '../../services/menuVorauswahl.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'menuVorauswahlEditor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css'],
})

export class MenuVorauswahlEditorComponent implements OnInit {

  public menu
  public aktuelleMenuVorauswahl = {}
  public buffer
  public id
  @ViewChildren("checkbox") private checkboxen: QueryList<ElementRef>;

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
    private menuVorauswahlService: MenuVorauswahlService,
  ) {
  }

  ngOnInit() {
    this.aktuelleMenuVorauswahl = this.menuVorauswahlService.menuVorauswahl.getValue()
    this.buffer = { ...this.aktuelleMenuVorauswahl[this.data.tag.datum].vorausgewaehlt}
    this.menu = this.data.menu
  }

  public aktualisiereVorauswahl(event, menuNr, menuId, position){
    if (event.checked){
      this.buffer.menues[menuNr][position] = menuId
    }else{
      this.buffer.menues[menuNr][position] = '0'
    }
  }

  public speichereVorauswahl(){
    this.aktuelleMenuVorauswahl[this.data.tag.datum].vorausgewaehlt = this.buffer
    this.menuVorauswahlService.menuVorauswahl.next(this.aktuelleMenuVorauswahl)
    let hauptmenuIndex = this.data.tag.vorausgewaehlt.menues[this.menu.menueNr]['1']
    if (hauptmenuIndex){
      let menuInformationen = this.data.tag.tagesMenues[hauptmenuIndex]
      this.menuVorauswahlService.updateMenuVorauswahlSeitenleiste(this.data.mandant, menuInformationen, this.buffer, this.menu, hauptmenuIndex, this.data.tag)
    }
    this.dialog.closeAll()
  }

  /** TODO Überbrückung für MMS -> Rausnehmen wenn MMS das übernimmt **/
  public hauptkomponenteBereitsGewaehlt(vorauswahl, menueId, position, menuNr){
    if (position == 1){
      for (let hauptkomponente in vorauswahl.menues){
        if (hauptkomponente != menuNr){
          if (vorauswahl.menues[hauptkomponente][position] == menueId){
            return true
          }
        }
      }
    }
    return false
  }

  public setzeVorauswahlzurueck(menuNr){
    for (let position in this.buffer.menues[menuNr]) {
      this.buffer.menues[menuNr][position] = '0'
    }
    for (let checkbox of this.checkboxen.toArray()) {
      checkbox['checked'] = false
    }
  }

  public verwerfeVorauswahl(){
    this.dialog.closeAll()
  }

  protected readonly environment = environment;
}
