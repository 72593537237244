<div id="header">
  <div id="notch"></div>

  <mat-toolbar class="header">
    <div class="header-content" [ngClass]="{'no-login' : !login}">
        <strong #header_strong [class.smaller-header]="login">{{ page_name }}</strong>
        <span #header_span *ngIf="login">{{ login.essensteilnehmer.vorname }}
          <ng-container *ngIf="login.guthaben !== null">| Guthaben: {{ login.guthaben | guthaben }} €</ng-container>
        </span>
    </div>

    <div id="toolbarOptionen">
      <mitteilungen-icon></mitteilungen-icon>
      <mat-icon
        #refresh_icon
        id="refreshIcon"
        (click)="refresh()" aria-hidden="false"
        aria-label="Neuladen"
      >refresh</mat-icon>
      <a routerLink="profile" routerLinkActive="_active" id="profilSchnellzugriff">
        <mat-icon aria-hidden="false" aria-label="Benutzerüberischt & -informationen">people_outline</mat-icon>
      </a>
    </div>
  </mat-toolbar>
</div>
