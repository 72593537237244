<div id="app">
  <mobil-toolbar></mobil-toolbar>
  <app-mobil-ladeanzeige *ngIf="loadingOverlay"></app-mobil-ladeanzeige>
  <div
    id="app-content"
    [class.hideContent] = "loadingOverlay"
    [class.showContent] = "!loadingOverlay"
  >
    <router-outlet></router-outlet>
  </div>
  <mobil-schnellzugriff></mobil-schnellzugriff>
</div>
