import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { AppStateService } from 'services/app/app-state.service';
import { BestellungService } from 'services/bestellung/bestellung.service';
import { KundeService } from 'services/kunden/kunde.service';
import {BenachrichtigungService} from '../../../services/prozesse/benachrichtigung.service';
import {MandantenService} from '../../../services/mandanten/mandanten.service';
import {zip} from 'rxjs';

@Component({
  selector: 'mobil-schnellzugriff',
  templateUrl: './mobil-schnellzugriff.component.html',
  styleUrls: ['./mobil-schnellzugriff.component.css'],
})
export class MobilSchnellzugriffComponent implements OnInit, AfterViewInit, OnDestroy {

  public hauptmenu
  public kunde
  public navigation
  public bestellungsBadge: boolean = false
  public bestellungsObserver
  public flag: any
  public mandant

  constructor(
    public bestellung_service: BestellungService,
    private app_state_service: AppStateService,
    private kunde_service: KundeService,
    private mandantService: MandantenService,
    private benachrichtigungsService: BenachrichtigungService,
  ) { }

  ngOnInit() {
    this.kunde_service.update.subscribe(() => {
      zip(
        this.kunde_service._kunde_get(),
        this.mandantService.mandant_get()
      ).subscribe((response) => {
        this.kunde = response[0]
        this.mandant = response[1]
      })
    })
    this.benachrichtigungsService.benachrichtigungen.subscribe(item => {
      this.flag = item
    })

    this.app_state_service.navigation.subscribe((navigation) => {
      if (navigation.aktuelle_seite != 'Hauptmenü') {
        this.hauptmenu = true
        this.navigation = navigation
      } else {
        this.hauptmenu = false
      }
    })
  }

  ngAfterViewInit() {
    this.bestellungsObserver = this.bestellung_service._bestellungen_anzahl.subscribe(anzahl => {
      if (anzahl > 0){
        this.bestellungsBadge = true
      }else{
        this.bestellungsBadge = false
      }
      setTimeout(() => {
        if (document.getElementById('warenkorbIcon')){
          const warenkorbIcon = document.getElementById('warenkorbIcon')
          if (anzahl === 1 && !warenkorbIcon.classList.contains('heartbeat')){
            warenkorbIcon.classList.add('heartbeat')
          }
          if (anzahl == 0){
            warenkorbIcon.classList.remove('heartbeat')
          }
        }
      }, 200)
    })
  }

  ngOnDestroy() {
    this.kunde_service.update.unsubscribe()
  }
}
