import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';
import {ApiCallService} from '../../../services/api/api-call.service';
import {map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {AnkuendigungPopupComponent} from './ankuendigung-popup/ankuendigung-popup.component';
import {MatDialog} from '@angular/material/dialog';
import {ContentBlockService} from '../../../services/prozesse/contentBlock.service';

@Injectable()
export class AnkuendigungService {

  public ankuendigungsBannerContent
  public ankuendigungsPopupContent

  constructor(
    private matDialog: MatDialog,
    private contentBlockService: ContentBlockService,
  ) {}

  public ankuendigungsContentPopup(){
    return this.contentBlockService.getContentBlock('App_Werbebanner')
      .pipe(switchMap((apiResponse:any) => {
        if (apiResponse && apiResponse[0]){
          this.ankuendigungsBannerContent = atob(apiResponse[0].content)
          return this.contentBlockService.getContentBlock('App_Werbepopup')
        }else{
          return of(false)
        }
      }),
      map((apiAnkuendigungsPopupResponse) => {
        if (apiAnkuendigungsPopupResponse){
          this.ankuendigungsPopupContent = atob(apiAnkuendigungsPopupResponse[0].content)
          return true
        }else{
          return false
        }
      })
    )
  }

  public ankuendigungsPopup(mandant, kunde) {
    const dialogRef = this.matDialog.open(AnkuendigungPopupComponent, {
      data: {werbeTitel: mandant.sonderKonfiguration['werbepopup']},
      panelClass: 'werbePopup'
    })
    dialogRef.afterClosed().subscribe(result => {
      if(!localStorage.getItem(`${kunde.kundeId}_werbebanner`)){
        localStorage.setItem(`${kunde.kundeId}_werbebanner`, 'true')
      }
    })
  }
}

