import {AfterViewInit, Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
  selector: 'checkboxBestellerinnerung',
  templateUrl: './checkboxBestellerinnerung.component.html',
  styleUrls: ['./checkboxBestellerinnerung.component.css']
})
export class CheckboxBestellerinnerungComponent implements OnInit, AfterViewInit {
  @Input() tabIndex
  @HostBinding('className') componentClass: string;
  public checked = window.localStorage.getItem('registrierungBestellerinnerung') == 'true'

  constructor() { }

  ngOnInit(): void {
    if (!window.localStorage.getItem('registrierungBestellerinnerung')){
      window.localStorage.setItem('registrierungBestellerinnerung', 'false');
    }
  }

  ngAfterViewInit(): void {}

  public bestellerinnerungSchreiben(event) {
    if (event.target.checked) {
      window.localStorage.setItem('registrierungBestellerinnerung', 'true');
    }else{
      window.localStorage.setItem('registrierungBestellerinnerung', 'false');
    }
  }
}
