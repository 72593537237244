import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'environments/environment';
import {DbReaderService} from "services/db-reader/db-reader.service";
import { Navigation } from 'services/prototype/navigation.class';
import {ServiceWorkerService} from 'services/navigation/service-worker.service';
import {Router} from '@angular/router';

@Injectable()
export class AppStateService {

  @HostListener('window:resize', ['$event'])

  public layout_type: string = 'mobil'
  public login = new BehaviorSubject(false)
  public side_menue: boolean = false
  public page_menu = new BehaviorSubject(false)
  public page_name = new BehaviorSubject('Unbenannt')
  public page_navigation = new BehaviorSubject('normal')
  public popup: boolean = false
  public popup_name: string = ''
  public popup_content: any
  public foodplan_popup: boolean = false
  public foodplan_content: object
  public state = true
  public page_icon = new BehaviorSubject('')

  public pages_icons = {
    "Kontakt" : 'perm_phone_msg',
    "Profile" : 'people_outline',
    "Speiseplan" : 'restaurant',
    "Warenkorb" : 'shopping_cart',
    "Bestellhistorie" : 'date_range',
    "Über die App" : 'mobile_friendly',
    "Mitteilungen" : 'messages',
    "Einstellungen" : 'settings',
    "Speiseplanübersicht" : 'list',
    "Hauptmenü" : 'home',
  }

  public navigation:BehaviorSubject<Navigation> = new BehaviorSubject(new Navigation)

  constructor(
    private db: DbReaderService,
    private router: Router,
    private serviceWorkerService: ServiceWorkerService,
  ){
    this.layout_type = environment.type;
    this.check_login()
  }

  public set_seitenname(neue_seite){
    let navigation:Navigation = this.navigation.getValue()
    navigation.set(neue_seite)
    this.navigation.next(navigation)
  }


  public set_page_navigation(link: string){
    this.page_navigation.next(link);
  }

  public set_page_name(name){
    this.page_name.next(name);
    this.page_icon.next(this.pages_icons[name])
  }

    private check_login(){
        this.db.get('active', 'User').subscribe(id => {
            if (id){
                this.db.get(id, 'User').subscribe(result => {
                    if (result){
                        //this.login_observer.next(result)
                        this.login.next(result)
                    }
                })
            }else{
                //this.login_observer.next(false)
                this.login.next(false)
            }
        })
    }

    public check_page_menu(){
        if (this.page_menu.getValue()){
            this.page_menu.next(false)
        }else{
            this.page_menu.next(true)
        }
    }

    public checkForExternalUrl(){
      this.check_page_menu()
      if (environment.mandant_id == '103716-ingolstadt'){
        window.open('https://www.caritas-wohnheime-werkstaetten.de/produkte-und-service/essen-auf-raedern/essen-auf-raedern/essen-auf-raedern', '_blank')
      }else{
        this.router.navigateByUrl('/faq')
      }
    }

  public pruefeUpdateBeiRoute(){
    this.router.events.subscribe((event) => {
      this.serviceWorkerService.serviceWorkerUpdate()
    })
  }

}
