<div class="message">
  <mat-expansion-panel *ngIf="info !== ''">
    <mat-expansion-panel-header class="message-header">
      <mat-panel-title>
        <strong>Nachricht Ihrer Einrichtung</strong>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <span *ngIf="info" [innerHTML]="info | bbCode"></span>
  </mat-expansion-panel>

  <div id="noMessage" *ngIf="info == ''" >
    <mat-icon>comments_disabled</mat-icon>
  </div>
</div>
