<div id="speiseplanKunde" *ngIf="mandant && kunde">
  <div id="speiseplanKundeAnkundigungsbanner">
    <werbebanner></werbebanner>
  </div>
  <div id="speiseplanKundeKalender">
    <speiseplan-kalender
      #speiseplan_kalender
      [mandant]="mandant"
      [kunde]="kunde"
      [apiZeitraum]="apiZeitraum"
      (zeitraum_update)="speiseplan_laden($event)"
    ></speiseplan-kalender>
  </div>



  <div id="speiseplanKundeContent">
    <div appSpeiseplanLeer *ngIf="speiseplan" [speiseplanMenu]="speiseplan.menu"></div>
    <div id="speiseplan-kunde" *ngIf="speiseplan">
      <mat-accordion multi>
        <mat-expansion-panel *ngFor="let tag of speiseplan.menu | keyvalue" [expanded]="speiseplan.menu | speiseplanAufklappen" class="speiseplanWba">
          <mat-expansion-panel-header class="labelHeaderCard">
            <speiseplanTagLabel class="splanLabel" [speiseplan]="speiseplan" [tag]="tag" [darstellung]="darstellung"></speiseplanTagLabel>
            <speiseplanTagIcon [bestellungen]="speiseplan.bestellung.tage[tag.key]" [speiseplan]="speiseplan" [config]="config" [tag]="tag"></speiseplanTagIcon>
          </mat-expansion-panel-header>
          <speiseplanTagContent
            speiseplanTag
            [speiseplan_tag]="tag"
            [darstellung]="darstellung"
            [modus]="modus"
            [mandant]="mandant"
            [kunde]="kunde"
            [tag]="tag"
            [speiseplan]="speiseplan"
            [config]="config"
          ></speiseplanTagContent>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>

