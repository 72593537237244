import {Injectable, ViewChild, ViewContainerRef} from '@angular/core';

@Injectable()
export class RegistrierungZusatzfelderService {

  constructor() {}

  ngOnInit() {
  }

  public zusatzFelderAnhaengen(neuKunde){
    if (window.localStorage.getItem('registrierungBestellerinnerung')) {
      if (window.localStorage.getItem('registrierungBestellerinnerung') == 'true') {
        neuKunde.freiText += 'Bestellerinnerung erwünscht<br>'
      }else{
        neuKunde.freiText += 'Keine Bestellerinnerung<br>'
      }
      window.localStorage.removeItem('registrierungBestellerinnerung')
    }

    if (window.localStorage.getItem('r_erweiterteZustimmung1')) {
      neuKunde.freiText += 'Ich beauftrage die HOT Menü GmbH mit der Herstellung und Lieferung der ausgewählten Mittagsspeisen.<br>'
      window.localStorage.removeItem('r_erweiterteZustimmung1')
    }

    if (window.localStorage.getItem('r_erweiterteZustimmung2')) {
      if (window.localStorage.getItem('r_erweiterteZustimmung2') == 'true') {
        neuKunde.freiText += 'MDS Serviceleistung: Ja<br>'
      }else{
        neuKunde.freiText += 'MDS Serviceleistung: Nein<br>'
      }
      window.localStorage.removeItem('r_erweiterteZustimmung2')
    }
    return neuKunde
  }

  public zusatzFelderLeeren(){
    window.localStorage.removeItem('registrierungBestellerinnerung')
    window.localStorage.removeItem('r_erweiterteZustimmung1')
    window.localStorage.removeItem('r_erweiterteZustimmung2')
  }

}
