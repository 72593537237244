import {AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MandantenService } from 'services/mandanten/mandanten.service';
import {RevisionService} from '../../../services/api/revision.service';
import {DomSanitizer} from '@angular/platform-browser';
import { AppStateService } from 'services/app/app-state.service';
import {VertragKuendigenComponent} from '../../../submodul/obs.shared/componenten/vertrag-kuendigen/vertrag-kuendigen.component';
import {KontaktComponent} from '../../../submodul/obs.shared/componenten/kontakt/kontakt.component';

@Component({
    selector: 'mobil-rechtliches',
    templateUrl: './mobil-rechtliches.component.html',
    styleUrls: ['./mobil-rechtliches.component.css'],
})

export class MobilRechtComponent implements OnInit, AfterViewInit {

  @ViewChild('rechtlichesContainer') htmlContainer: ElementRef

  constructor(
    private mandantService: MandantenService,
    private state_service: AppStateService,
    private revision_service: RevisionService,
    private route: ActivatedRoute,
    private domSanitizer: DomSanitizer,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.state_service.set_seitenname('rechtliches')
    this.state_service.set_page_navigation('normal');
  }

  public rechts_txt:any
  public rechts_content:any
  public content: string
  public name: string
  public mandant
  public kuendigung
  public subscription
  public localContent = false

  ngOnInit() {
    this.revision_service.revision_mandant('mandant/get')
    this.route.queryParams.subscribe(params => {
      this.mandantService.mandant_get().subscribe((mandant) => {
        this.mandant = mandant
        this.kuendigung = mandant.sonderKonfiguration.API_1_5.terminateContractURL
        this.rechts_content = [
          { content: this.domSanitizer.bypassSecurityTrustHtml(mandant.betreiberDaten.agb), name: 'agb', icon: 'fact_check'},
          { content: this.domSanitizer.bypassSecurityTrustHtml(mandant.betreiberDaten.datenschutz), name: 'datenschutz', icon: 'security'},
          { content: this.domSanitizer.bypassSecurityTrustHtml(mandant.betreiberDaten.widerruf), name: 'widerruf', icon: 'pan_tool'},
          { content: this.domSanitizer.bypassSecurityTrustHtml(mandant.betreiberDaten.impressum), name: 'impressum', icon: 'gavel'},
          { content: this.domSanitizer.bypassSecurityTrustHtml(mandant.betreiberDaten.kontakt), name: 'kontakt', icon: 'perm_phone_msg'},
          { content: {}, name: 'kuendigung' , icon: 'playlist_remove'}
        ]
        this.rechts_txt = params.id
        this.rechts_content.filter((element) => {
          if (element.name == params.id){
            if (element.name == 'kuendigung') {
              this.viewContainerRef.createComponent(VertragKuendigenComponent)
            }else if(element.name == 'kontakt'){
              this.viewContainerRef.createComponent(KontaktComponent)
            } else {
              this.localContent = element.content
              this.state_service.set_page_name(element.name.charAt(0).toUpperCase() + element.name.slice(1));
            }
          }
        })
      })
    });
  }

  ngAfterViewInit() {
    const element = document.getElementById('rechtlichesContent');
    element.scrollIntoView();
  }

}
