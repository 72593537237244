import { Component, OnInit } from '@angular/core';
import {KundeService} from '../../../../services/kunden/kunde.service';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'barcodeKundennummer',
    templateUrl: './barcodeKundennummer.component.html',
    styleUrls: ['./barcodeKundennummer.component.css'],
})
export class BarcodeKundennummerComponent implements OnInit {

  public kundennummer

  constructor(
      private kundeService: KundeService,
  ) {}

  ngOnInit() {
    this.kundeService._kunde_get().subscribe(kunde => this.kundennummer = kunde.kundenNr);
  }

  protected readonly environment = environment;
}
