import { Component, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { AppStateService } from 'services/app/app-state.service';
import { Subscription, zip } from 'rxjs';
import { BestellungService } from 'services/bestellung/bestellung.service';
import { KundeService } from 'services/kunden/kunde.service';
import { MandantenService } from 'services/mandanten/mandanten.service';
import { SpeiseplanService } from 'submodul/obs.speiseplan/services/speiseplan.service';
import { DbReaderService } from 'services/db-reader/db-reader.service';

@Component({
  selector: 'mobil-speiseplan-kunde',
  templateUrl: './mobil-speiseplan-kunde.component.html',
  styleUrls: ['./../../../submodul/obs.speiseplan/einzelbesteller/speiseplanDarstellung/speiseplanDarstellungWBA.css'],
})
export class MobilSpeiseplanKundeComponent implements OnInit, OnDestroy {

  @ViewChild('speiseplan_kalender') speiseplan_kalender
  @ViewChild('speiseplan_details') speiseplan_details
  public mandant = null
  public kunde = null
  public speiseplan: any
  public config
  public config_subscribtion: Subscription
  public modus
  public zustellpause
  public speiseplanKunde
  public apiZeitraum
  public zeitraum
  public darstellung = 2

  constructor(
    private db: DbReaderService,
    private kunden_service: KundeService,
    private mandant_service: MandantenService,
    private speiseplan_service: SpeiseplanService,
    private bestellung_service: BestellungService,
    private state_service: AppStateService,
  ) {
    this.state_service.set_page_name('Speiseplan');
    this.state_service.set_seitenname('speiseplan-kunde')
    this.state_service.set_page_navigation('normal');
  }

  ngOnInit() {
    zip(
      this.mandant_service.mandant_get(),
      this.kunden_service._kunde_get(),
      this.speiseplan_service.speiseplan_zeitraum()
    ).subscribe(response => {
      this.mandant = response[0]
      this.kunde = response[1]
      this.apiZeitraum = response[2]
    })
    this.config_subscribtion = this.speiseplan_service.config.subscribe((config) => { this.config = config })
  }

  ngOnDestroy() {
    if (!this.config_subscribtion) this.speiseplan_service.config.unsubscribe()
  }

  speiseplan_laden(zeitraum) {
    this.zeitraum = zeitraum
    this.modus = this.speiseplan_kalender.modus
    let speiseplan_response = this.speiseplan_service.speiseplan_kunde(this.kunde.bestellerId, zeitraum.start, zeitraum.ende)
    let bestellung_response = this.bestellung_service.bestellung_kunde(this.kunde.bestellerId, zeitraum.start, zeitraum.ende)
    zip(speiseplan_response, bestellung_response).subscribe((response: any) => {
      this.speiseplanKunde = response[0]
      this.speiseplan = {
        bestellschluss: response[0].bestellschlussMsgs,
        menu: response[0].kunden[this.kunde.kundeId].speisePlanTage,
        menutexte: response[0].menuetexte,
        bestellung: response[1].kunden[this.kunde.bestellerId],
        feiertage: this.mandant.feiertage,
        zustellpause: Object.keys(this.kunde.zustellPausen).length > 0 ? this.kunde.zustellPausen : null,
      }
      if (this.mandant.sonderKonfiguration['nurBestelltSichtbar']){
        this.speiseplan = this.speiseplan_service.nurbestellteMenus(this.mandant, this.speiseplan)
      }
      this.db.add(this.speiseplan.bestellschluss[0].text, 'bestellschluss', 'Cache')
      this.bestellung_service._menutexte = Object.assign(this.bestellung_service._menutexte, response[1].menuetexte)
    })
  }
}
