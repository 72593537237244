<div id="erweiterteZustimmung" [class.mobil]="environment.type !== 'web'">
  <mat-checkbox (click)="$event.preventDefault()" [disableRipple]="true" [checked]="true">
    <strong>*</strong>Ich beauftrage die HOT Menü GmbH mit der Herstellung und Lieferung der ausgewählten Mittagsspeisen.
  </mat-checkbox>

  <mat-checkbox (click)="erweiterteZustimmungSchreiben($event)" [checked]="checked2">
    Für Einrichtungen mit Ausgabeservice bestätige ich, dass ich den vollumfänglichen Service durch die MDS Mitteldeutsche
    Service- und Dienstleistungsgesellschaft mbH & Co. KG (nachfolgend MDS genannt), einschließlich der Bereitstellung und
    Reinigung von Geschirr und Besteck sowie der Nutzung und Reinigung des Speiseraums, in Anspruch nehme.

    Für den Fall, dass Sie den vollumfänglichen Service durch die MDS nicht in Anspruch nehmen / bestätigen, beachten Sie
    bitte folgende Hinweise:

    Es erfolgt keine Bereitstellung von Geschirr und Besteck durch das Servicepersonal. Stattdessen werden die Mahlzeiten
    in vorabgefüllten Einweg-Einzelportionsschalen bereitgestellt. Das Mittagessen kann zudem nicht im Speiseraum verzehrt
    werden.

    Bitte beachten Sie ferner, dass für die Bereitstellung der Speisen in Einzelportionsschalen der HOT Menü GmbH ein
    zusätzlicher Aufwand bei der Produktion, der Lieferung und der Verwaltung entsteht. Für diesen Mehraufwand sowie das
    erforderliche Verpackungsmaterial berechnet die HOT Menü GmbH im normalen Rechnungslauf eine Pauschale von 1,70 € pro
    Mittagsmahlzeit und für Kindertagesstätten mit Frühstücks- und Vesperversorgung jeweils 0,50 € pro Frühstücks- und
    Vespermahlzeit. Vielen Dank für Ihr Verständnis.

    Wenn Sie die Serviceleistungen von MDS nicht in Anspruch nehmen möchten, bitten wir Sie zusätzlich, uns rechtzeitig
    zu kontaktieren, um eventuelle Missverständnisse zu vermeiden. Für weitere Rückfragen stehen wir Ihnen jederzeit
    gerne zur Verfügung.
  </mat-checkbox>
</div>
