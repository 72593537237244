import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AppStateService } from 'services/app/app-state.service';
import {timer, zip} from 'rxjs';
import { KundeService } from 'services/kunden/kunde.service';
import { MandantenService } from 'services/mandanten/mandanten.service';
import { RevisionService } from 'services/api/revision.service';
import {environment} from 'environments/environment';
import {RegistrierungService} from '../../../services/registrierung/registrierung.service';
import {Router} from '@angular/router';
import {ServiceWorkerService} from 'services/navigation/service-worker.service';
import {error} from 'protractor';
import {FaqService} from '../../../submodul/obs.shared/componenten/faq/faq.service';
import {GuthabenAufladenSopro} from '../../../services/sopro/guthabenAufladen.sopro';
import {FlexLinkService} from '../../../services/navigation/flexLink.service';

@Component({
    selector: 'mobil-hauptmenu',
    templateUrl: './mobil-hauptmenu.component.html',
    styleUrls: ['./mobil-hauptmenu.component.css'],
})
export class MobilHauptmenuComponent implements OnInit, AfterViewInit {

    public kunde: boolean | object = false;
    public profilAnzahl = 0
    public mandant: any
    public message: any
    public length: any
    public enviroment = environment
    public serviceWorkerStatus: boolean
    public serviceWorkerUpdate: boolean
    public last_sync: Date
    public faq
    public mandantId
    public logo: string = "assets/web/logo.webp";
    public isLogoLoaded: boolean = true;

    constructor(
      public app_state_service: AppStateService,
      private kunde_service: KundeService,
      private mandant_service: MandantenService,
      private revisions_service: RevisionService,
      public registrationService: RegistrierungService,
      private router: Router,
      public serviceWorker: ServiceWorkerService,
      public faq_service: FaqService,
      public guthabenSopro: GuthabenAufladenSopro,
      public flexLink: FlexLinkService,
    ) {
      this.app_state_service.set_page_name('Hauptmenü');
      this.app_state_service.set_seitenname('Hauptmenü')
      this.app_state_service.set_page_navigation('normal');
    }

    ngOnInit() {
      this.revisions_service.revision_mandant('mandant/get')
      zip(
        this.kunde_service._kunde_get(),
        this.mandant_service.mandant_get()
      ).subscribe((response) => {
        this.kunde = response[0]
        this.mandant = response[1]
        this.mandantId = response[0].mandantId
        this.length = Object.keys(response[1].speisePlanIds).length
        this.kunde_service.kundeZaehlenInDerDatenbank().subscribe(kunden => {
          const index = kunden.indexOf('active');
          if (index > -1) {
            kunden.splice(index, 1);
          }
          this.profilAnzahl = kunden.length
        })
      })
      this.last_sync = new Date(localStorage.getItem('last_sync'));
      this.serviceWorker.updates.checkForUpdate().then(status => this.serviceWorkerUpdate = status)
      this.serviceWorkerStatus = this.serviceWorker.serviceWorkerStatus()
    }

    public faqParams(param){
      if (param == 'installation'){
        this.router.navigate(['/faq'], {queryParams: { entry:"Installationsanleitung"}})
      }
      if (param == 'guthaben'){
        this.router.navigate(['/faq'], {queryParams: { entry:"Guthaben aufladen"}})
      }
    }

    ngAfterViewInit(){
      timer(300).subscribe(() => {
        let links:any = document.getElementsByClassName("mobile-menue-link")
        for (let link of links){
          link.classList.add('flip-in')
        }
      })
    }

    public vertragKuendigen(){
      if (/kuendigen_step1/gm.test(this.mandant.sonderKonfiguration.API_1_5.terminateContractURL)) {
        this.flexLink.flexLinkPruefen('kuendigung')
      } else {
        window.open(this.mandant.sonderKonfiguration.API_1_5.terminateContractURL, '_blank')
      }
    }

  public home_link(): any{
    if ((this.kunde && this.mandant.navigation_logged_in.home_content) || (!this.kunde && this.mandant.navigation_root.home_content)){
      this.router.navigateByUrl('/home')
    }
    if ((this.kunde && this.mandant.navigation_logged_in.home_link) || (!this.kunde && this.mandant.navigation_root.home_link)){
      window.open(this.mandant.homeLink,"_blank");
    }
  }

  public oeffentlicherBereichConfig(){
    if (!this.kunde && (this.mandant.navigation_root.home_link || this.mandant.navigation_root.home_content || this.mandant.navigation_root.menu)){
      return true
    }
    if (this.kunde && (this.mandant.navigation_logged_in.home_link || this.mandant.navigation_logged_in.home_content)){
      return true
    }
    return false
  }

  public homeConfig(){
    if (!this.kunde && (this.mandant.navigation_root.home_link || this.mandant.navigation_root.home_content)){
      return true
    }
    if (this.kunde && (this.mandant.navigation_logged_in.home_link || this.mandant.navigation_logged_in.home_content)){
      return true
    }
    return false
  }

  // TODO: hier nen Service drauß machen, ich bin zu faul grad lol
  onImageError() {
    this.logo = "assets/web/icon-1024x1024.webp";
  }
}
