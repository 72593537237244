<ng-container *ngIf="formGroup && feldConfig">
  <div [formGroup]="formGroup" [class]="feldConfig.name" id="formularFeldPassword">
    <div class="registrationFeldHeader">
      <label>
        <span>{{ feldConfig.text }}</span>
        <strong *ngIf="feldConfig && feldConfig.pflicht"> *</strong>
      </label>
      <mat-icon
        *ngIf="feldConfig.description"
        [title]="feldConfig.description"
        (click)="registrationService.zeigeInfoDialog(feldConfig.description)"
      >info</mat-icon>
    </div>
    <div class="passwortInputContainer">
      <input
        [class.registrationFormFehler]="formGroup.controls[feldConfig.name].errors"
        [formControlName]="feldConfig.name"
        [tabIndex]="tabIndex"
        [type]="visibility ? 'text' : 'password'"
        (focusin)="passwortKomplexZeigen = true; passwortRegelnService.validiereRegelwerk($event)"
        (focusout)="passwortKomplexZeigen = false"
        (keyup)="passwortRegelnService.validiereRegelwerk($event)"
        cdkOverlayOrigin #trigger="cdkOverlayOrigin"
        autocomplete="new-password"
        maxlength="20"
      />

      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="passwortKomplexZeigen"
      >
        <div id="passwortKomplexOverlay" *ngIf="passwortKomplexZeigen && passwortKomplexActive">
          <ul id="passwordComplex">
            <ng-container *ngFor="let require of mandant.sonderKonfiguration.API_1_5.passwordComplex.require">
              <li
                [id]="require.rule"
                [class.valid]="passwortRegelnValidierung.required[require.rule]"
              >{{ require.description }}</li>
            </ng-container>
          </ul>
          <span>Es müssen noch {{validCounter}} der folgenden Kriterien erfüllt sein:</span>
          <ul id="passwordComplexOpt">
            <ng-container *ngFor="let optional of mandant.sonderKonfiguration.API_1_5.passwordComplex.optional">
              <li
                [id]="optional.rule"
                [class.valid]="passwortRegelnValidierung.optional[optional.rule]"
              >{{ optional.description }}</li>
            </ng-container>
          </ul>
        </div>
      </ng-template>

      <mat-icon (click)="zeigePasswort()">{{visibility ? 'visibility_off' : 'visibility'}}</mat-icon>
    </div>

    <span class="registrationBeschreibungFehler" *ngIf="formGroup.controls[feldConfig.name].hasError">{{ formGroup.controls[feldConfig.name].errors | error }}</span>
  </div>
</ng-container>
