<div id="bestellhistorie-kunde">
  <mat-card id="bestellZeitraumPicker" *ngIf="zeitraum" (click)="bestellungZeitraum()">
    <div id="bestellZeitraum">
      <strong>Bestellzeitraum</strong>
      <span *ngIf="this.zeitraum.von.unix() !== this.zeitraum.bis.unix()">{{ this.zeitraum.von | datum:'dd.MM.YYYY'}} - {{ this.zeitraum.bis | datum:'dd.MM.YYYY'}}</span>
      <span *ngIf="this.zeitraum.von.unix() === this.zeitraum.bis.unix()">{{ this.zeitraum.von | datum }} - {{ this.zeitraum.von | datum: 'wochentag' }}</span>
    </div>
    <mat-icon>calendar_month</mat-icon>
  </mat-card>
  <div id="bestellhistorie-zusammenfassung" *ngIf="bestellung && !bestellungLeer">
    <mat-accordion class="example-headers-align" multi>
      <mat-expansion-panel *ngFor="let tag of bestellung.tage | keyvalue" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>{{ tag.key | datum }} - {{ tag.key | datum:'wochentag' }}</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="bestellung" *ngFor="let menu of tag.value.bestellMenues | speiseplanTagesmenus:bestellung.tage[tag.key].bestellMenues;" (click)="bestellungDetails(menu)">
          <div class="bestellung-flexContainer heading">
            <strong>{{ bestellung.menutexte[menu.menueTextId].bezeichnung }}</strong>
            <strong *ngIf="mandant.preisPositionInSplan !== 'none'">{{ menu.einzelPreis | euroPrice}}</strong>
          </div>
          <div class="bestellung-flexContainer">
            <span [innerHTML]="bestellung.menutexte[menu.menueTextId].menueText | bbCode"></span>
            <mat-icon>more_horiz</mat-icon>
          </div>
          <div class="bestellung-flexContainer bestellung-zeile-abschluss">
            <strong bestellung-update [bestellung]="menu">Menge: {{ menu.mengeAlt }}</strong>
            <strong *ngIf="mandant.preisPositionInSplan !== 'none'" >{{ menu.gesamtBetrag | euroPrice }}</strong>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-card
      id="bestellhistorie-footer"
      class="bestellung-flexContainer"
      *ngIf="mandant.preisPositionInSplan !== 'none' && bestellung && (bestellung.tage | json) != '{}'"
    >
      <div id="bestellPreis">
        <strong>Gesamtpreis:</strong>
      </div>
      <strong>{{ bestellungenPreis | bestellhistoriePreis}}€</strong>
    </mat-card>
  </div>
  <div id="warenkorb-leer" *ngIf="bestellungLeer">
    <mat-icon>event_busy</mat-icon>
  </div>
</div>
