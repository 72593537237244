import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[appRegistrierungZusammenfassungParser]'
})
export class RegistrierungZusammenfassungParserDirective implements AfterViewInit{

  @Input() formData
  @Input() formConfig

  constructor(
    private el: ElementRef
  ) { }

  ngAfterViewInit() {
    let aktiveFelder = 0
    let leereFelder = Object.keys(this.formConfig).length
    for (let feldName in this.formConfig){
      if (this.formConfig[feldName].active) {
        aktiveFelder ++
      }
      if (this.formData[feldName] == ''){
        leereFelder --
      }
    }
    if (aktiveFelder == 0){
      this.el.nativeElement.style.display = 'none'
    }
    if (leereFelder == 0){
      this.el.nativeElement.lastChild.innerHTML = 'Keine Angaben'
      this.el.nativeElement.lastChild.removeAttribute('id');
      this.el.nativeElement.lastChild.removeAttribute('class');
    }
  }
}
