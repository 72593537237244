import {Component, Input, OnInit} from '@angular/core';
import {AnkuendigungService} from '../ankuendigung.service';

@Component({
  selector: 'app-ankuendigung-banner',
  templateUrl: './ankuendigung-banner.component.html',
  styleUrls: ['./ankuendigung-banner.component.css']
})
export class AnkuendigungBannerComponent implements OnInit {

  @Input('mandant') mandant
  @Input('kunde') kunde
  public ankuendigungsContent: string

  constructor(
    private ankuendigungsService: AnkuendigungService,
  ) { }

  ngOnInit(): void {
    this.ankuendigungsService.ankuendigungsContentPopup().subscribe(apiResult => {
      this.ankuendigungsContent = this.ankuendigungsService.ankuendigungsBannerContent
      this.erstesMalAnteasern()
    })
  }

  private erstesMalAnteasern(){
    if (!localStorage.getItem(`${this.kunde.kundeId}_werbebanner`) && this.ankuendigungsService.ankuendigungsBannerContent){
      this.onClick()
    }
  }

  public onClick() {
    this.ankuendigungsService.ankuendigungsPopup(this.mandant, this.kunde)
  }
}
