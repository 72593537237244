import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'zusatzfelderZusammenfassung',
  templateUrl: './zusatzfelderZusammenfassung.component.html',
  styleUrls: ['./zusatzfelderZusammenfassung.component.css']
})
export class ZusatzfelderZusammenfassungComponent implements OnInit {
  public bestellerinnerung = window.localStorage.getItem('registrierungBestellerinnerung')
  public erweiterteZustimmung1 = window.localStorage.getItem('r_erweiterteZustimmung1')
  public erweiterteZustimmung2 = window.localStorage.getItem('r_erweiterteZustimmung2')

  constructor() { }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  protected readonly environment = environment;
}
