<div
  *ngIf="tag"
  id="speiseplanTagLabel"
  speiseplanLabel
  [speiseplan]="speiseplan"
  [tag]="tag"
  [darstellung]="darstellung"
  [modus]="modus"
  [kundeId]="kundeId"
  [class.vertikal]="darstellung == 1 && !mobil"
>
  <div class="speiseplanTagLabelNormal">
    <strong>{{ tag.key | date: 'dd.MM.YY' }} - {{ tag.key | datum:'wochentag' }}</strong>
  </div>
  <div class="speiseplanTagLabelInfo"
       *ngIf="feiertag || zustellPause"
       [class.feiertag]="feiertag"
       [class.zustellpause]="zustellPause"
       [class.alle]="feiertag && zustellPause"
       (click)="openDialog()"
  >
    <mat-icon>info</mat-icon>
    <ng-container *ngIf="!mobil && darstellung == 2">
      <strong *ngIf="zustellPause">Zustellpause</strong>
      <strong *ngIf="feiertag && zustellPause">& </strong>
      <strong *ngIf="feiertag">{{ feiertag.bezeichnung }}</strong>
    </ng-container>
  </div>
  <div id="speiseplanTagLabelPlatzhalter"></div>
</div>
