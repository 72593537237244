import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {RegistrierungService} from '../../../../../services/registrierung/registrierung.service';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'einrichtungsSelektion',
  templateUrl: './einrichtungsSelektion.component.html',
  styleUrls: ['./einrichtungsSelektion.component.css']
})
export class EinrichtungsSelektionComponent implements OnInit {
  @Input() mandant
  @Input() public formGroup: UntypedFormGroup
  @Input() feldConfig
  @HostBinding('className') componentClass: string;
  public einrichtungsGruppen = []

  constructor(
    public registrationService: RegistrierungService
  ) { }

  ngOnInit(): void {
    this.componentClass = 'einrichtung_nameEinrichtung';
    this.holeEinrichtungsGruppen()
  }

  public holeEinrichtungsGruppen(){
    this.einrichtungsGruppen = this.mandant.sonderKonfiguration['API_1_5']['einricht2GrpMap'][this.formGroup.controls['einrichtung_nameEinrichtung'].value]
    if (!this.einrichtungsGruppen || this.einrichtungsGruppen.length == 0){
      this.formGroup.controls['einrichtung_nameGruppe'].disable()
    }else{
      this.formGroup.controls['einrichtung_nameGruppe'].enable()
    }
  }

  public onChangeEinrichtungsName(){
    if (this.feldConfig['einrichtung_nameGruppe'].active) {
      this.holeEinrichtungsGruppen()
      this.formGroup.controls['einrichtung_nameGruppe'].setValue('')
      this.formGroup.controls['einrichtung_nameGruppe'].reset()
    }
  }

  protected readonly environment = environment;
}
