<div *ngIf="mitteilungen" id="mitteilungen">
  <ng-container *ngIf="data.vorhanden">
    <div id="globalMessage" *ngIf="mitteilungen.global" class="mitteilungen">
      <div class="messageContainer">
        <strong [matBadge]="neu['global'] ? 'true' : null" matBadgeSize="small">Wichtige Ankündigung</strong>
      </div>
      <span [innerHTML]="mitteilungen.global"></span>
    </div>

    <div id="mandantenMessage" *ngIf="mitteilungen.mandant" class="mitteilungen">
      <div class="messageContainer">
        <strong [matBadge]="neu['mandant'] ? 'true' : null" matBadgeSize="small">Nachricht Ihres Caterers</strong>
      </div>
      <span [innerHTML]="mitteilungen.mandant"></span>
    </div>

    <div id="einrichtungsMessage" *ngIf="mitteilungen.einrichtung && kunde" class="mitteilungen">
      <div class="messageContainer">
        <strong [matBadge]="neu['einrichtung'] ? 'true' : null" matBadgeSize="small">Nachricht Ihrer Einrichtung</strong>
      </div>
      <span [innerHTML]="mitteilungen.einrichtung.info | bbCode"></span>
    </div>
  </ng-container>

  <ng-container *ngIf="!data.vorhanden">
    <div id="keineMitteilungen">
      <div class="messageContainer">
        <strong>Information</strong>
      </div>
      <span>Zurzeit liegen keine neuen Mitteilungen vor.</span>
    </div>

  </ng-container>

  <button mat-button mat-dialog-close (click)="mitteilungenService.mitteilungsZustandSichern()" id="gelesenBtn">
    <mat-icon>comment</mat-icon>
    <span>Mitteilungen gelesen</span>
  </button>
</div>
