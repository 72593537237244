import { Component } from '@angular/core';

@Component({
  selector: 'app-mobil-ladeanzeige',
  templateUrl: './mobil-ladeanzeige.component.html',
  styleUrls: ['./mobil-ladeanzeige.component.css']
})
export class MobilLadeanzeigeComponent {
  constructor() {
  }
}
