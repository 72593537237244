import {NgModule} from '@angular/core';
import {CommonModule, NgForOf} from '@angular/common';
import {RouterModule} from '@angular/router';
import {PipeModule} from 'pipe/pipe.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {MobilLoginComponent} from 'components/mobil/mobil-login/mobil-login.component';
import { MobilMessageComponent } from './mobil-messages/mobil-messages.component';
import { FehlermeldungComponent } from './fehlermeldung/fehlermeldung.component';
import { MobilSpeiseplanKundeComponent } from './mobil-speiseplan-kunde/mobil-speiseplan-kunde.component';
import { MobilSpeiseplanMandantComponent } from './mobil-speiseplan-mandant/mobil-speiseplan-mandant.component';
import { DirectiveModule } from 'directives/directives.module';
import { MaterialModule } from 'assets/material.module';
import { MobilWarenkorbComponent } from './mobil-warenkorb/mobil-warenkorb.component';
import { MobilSchnellzugriffComponent } from './mobil-schnellzugriff/mobil-schnellzugriff.component';
import { MobilBestellhistorieComponent } from './mobil-bestellhistorie/mobil-bestellhistorie.component';
import { MobilBestellhistoriePickerComponent } from './mobil-bestellhistorie_picker/mobil-bestellhistorie_picker.component';
import { MobilToolbarComponent } from './mobil-toolbar/mobil-toolbar.component';
import { MobilProfileComponent } from './mobil-profile/mobil-profile.component';
import { MobilHauptmenuComponent } from './mobil-hauptmenu/mobil-hauptmenu.component';
import { MobilSpeiseplanOverviewComponent } from './mobil-speiseplan-overview/mobil-speiseplan-overview.component';
import { SharedModule } from '../../submodul/obs.shared/shared.module';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.components';
import { WebDialogComponent } from 'components/web/web-dialog/web-dialog.component';
import { MobilDialogUpdateComponent } from './mobil-dialog-update/mobil-dialog-update.component';
import { AnkuendigungPopupComponent } from './ankuendigung/ankuendigung-popup/ankuendigung-popup.component';
import { AnkuendigungBannerComponent } from './ankuendigung/ankuendigung-banner/ankuendigung-banner.component';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {MobilRechtComponent} from './mobil-rechtliches/mobil-rechtliches.component';
import {SpeiseplanModule} from '../../submodul/obs.speiseplan/speiseplan.module';
import {MobilLadeanzeigeComponent} from './mobil-ladeanzeige/mobil-ladeanzeige.component';
import {AnkuendigungService} from './ankuendigung/ankuendigung.service';
import {SeitenleisteService} from '../../submodul/obs.seitenleiste/seitenleiste.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        PipeModule,
        DirectiveModule,
        MaterialModule,
        SharedModule,
        NgForOf,
        SpeiseplanModule,
    ],
    declarations: [
        MobilLoginComponent,
        MobilMessageComponent,
        FehlermeldungComponent,
        ConfirmDialogComponent,
        MobilSpeiseplanKundeComponent,
        MobilSpeiseplanMandantComponent,
        MobilWarenkorbComponent,
        MobilBestellhistorieComponent,
        MobilBestellhistoriePickerComponent,
        MobilSchnellzugriffComponent,
        MobilToolbarComponent,
        MobilHauptmenuComponent,
        MobilProfileComponent,
        MobilSpeiseplanOverviewComponent,
        WebDialogComponent,
        MobilDialogUpdateComponent,
        AnkuendigungPopupComponent,
        AnkuendigungBannerComponent,
        MobilRechtComponent,
        MobilLadeanzeigeComponent,
    ],
    exports: [
        MobilLoginComponent,
        MobilMessageComponent,
        FehlermeldungComponent,
        ConfirmDialogComponent,
        MobilSpeiseplanKundeComponent,
        MobilSpeiseplanMandantComponent,
        MobilBestellhistorieComponent,
        MobilBestellhistoriePickerComponent,
        MobilWarenkorbComponent,
        MobilSchnellzugriffComponent,
        MobilToolbarComponent,
        MobilHauptmenuComponent,
        MobilProfileComponent,
        WebDialogComponent,
        MobilRechtComponent,
        MobilLadeanzeigeComponent,
    ],
  providers: [
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} },
    { provide: MatBottomSheetRef, useValue: {} },
    AnkuendigungService,
    SeitenleisteService
  ]
})
export class MobilModule {
}
