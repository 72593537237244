import { Component, OnInit } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {MatTableDataSource} from '@angular/material/table';
import {MandantenService} from '../../../../services/mandanten/mandanten.service';

@Component({
    selector: 'wartungsabgleich',
    templateUrl: './wartungsabgleich.component.html',
    styleUrls: ['./wartungsabgleich.component.css']
})
export class WartungsabgleichComponent implements OnInit {

  public os: string
  public dataSource
  public displayedColumns: string[] = ['position'];
  public liste = [
    {name: 'AGB', content: ''},
    {name: 'Datenschutz', content: ''},
    {name: 'Widerruf', content: ''},
    {name: 'Impressum', content: ''},
    {name: 'Kontakt', content: ''},
  ]
  constructor(
    private mandantService: MandantenService
  ){
    this.os = environment.type
  }

  ngOnInit() {
    this.mandantService.mandant_get().subscribe(mandant => {
      this.liste[0].content = mandant.betreiberDaten.agb
      this.liste[1].content = mandant.betreiberDaten.datenschutz
      this.liste[2].content = mandant.betreiberDaten.widerruf
      this.liste[3].content = mandant.betreiberDaten.impressum
      this.liste[4].content = mandant.betreiberDaten.kontakt
      this.liste = this.liste.reduce((acc, item) => {
        if (item.content != ''){
          acc.push(item);
        }
        return acc;
      }, []);
      this.dataSource = new MatTableDataSource(this.liste);
    })
  }
}
