import { Injectable } from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {MandantenService} from '../mandanten/mandanten.service';
import {MatDialog} from '@angular/material/dialog';
import {AnkuendigungPopupComponent} from '../../components/mobil/ankuendigung/ankuendigung-popup/ankuendigung-popup.component';

@Injectable({
  providedIn: 'root'
})
export class AnkuendigungService {

  private uberwachendeRouten = [
    '/speiseplan'
  ]

  constructor(
    private router: Router,
    private mandantService: MandantenService,
    private matDialog: MatDialog
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart && this.uberwachendeRouten.find(item => item === event.url)) {
        this.checkNachAnkuendigung()
      }
    })
  }

  public checkNachAnkuendigung() {
    this.mandantService.mandant_get().subscribe(mandant => {
      if (mandant.sonderKonfiguration['werbepopup'] && localStorage.getItem('werbepopup') !== mandant.sonderKonfiguration['werbepopup']){
        this.ankuendigungsPopup(mandant)
      }
    })
  }

  public ankuendigungsPopup(mandant) {
    const dialogRef = this.matDialog.open(AnkuendigungPopupComponent, {
      data: {werbeTitel: mandant.sonderKonfiguration['werbepopup']},
      panelClass: 'werbePopup'
    })
    dialogRef.afterClosed().subscribe(result => {
      if(!localStorage.getItem('werbebanner')){
        localStorage.setItem('werbebanner', 'true')
      }
    })
  }
}
