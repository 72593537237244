<div id="warenkorb" *ngIf="bestellungen && bestellungen_anzahl > 0">
  <div id="warenkorb-bestelltage" >
    <div *ngFor="let tag of bestellungen.tage | keyvalue">
      <div id="warenkorbTagTitel">
        <speiseplanTagLabel *ngIf="speiseplanKunde"
          [tag]="tag"
          [speiseplan]="speiseplanKunde"
          [darstellung]="2"
          [modus]="1"
          [kundeId]="kunde.kundeId"
        ></speiseplanTagLabel>
      </div>
      <mat-card class="bestelltag">
        <div class="bestelltag-menus" [class.fehler]="bestellungen.error">
          <div class="bestellungtag-menu" *ngFor="let menu of tag.value.bestellMenues | speiseplanTagesmenus:bestellungen.tage[tag.key].bestellMenues; index as zeile">
            <ng-container *ngIf="menu.mengeNeu || menu.mengeAlt != 0">
              <div class="warenkorb-zeile" (click)="bestellungDetails(menu)" [class.first]="zeile == 0">
                <div>
                  <strong class="menu-bezeichnun bestelltag-strong">{{ menutexte[menu.menueTextId].bezeichnung }}</strong>
                  <strong *ngIf="mandant.preisPositionInSplan !== 'none'" class="menu-einzelpreis bestelltag-strong">{{ menu.einzelPreis | euroPrice }}</strong>
                </div>
                <div>
                  <span class="bestellung-info" [innerHTML]="menutexte[menu.menueTextId].text | bbCode"></span>
                  <mat-icon>more_horiz</mat-icon>
                </div>
                <div class="warenkorb-zeile-bestellung">
                  <strong class="menu-menge">
                    Menge: {{ menu.mengeNeu }}
                    <span
                      class="menu-update"
                      bestellung-update
                      [bestellung]="menu"
                      [class.plus]="menu.mengeNeu > menu.mengeAlt"
                      [class.minus]="menu.mengeNeu < menu.mengeAlt">
                      <span *ngIf="menu.mengeNeu > menu.mengeAlt">+</span>
                      {{ menu.mengeNeu - menu.mengeAlt }}
                </span>
                  </strong>
                  <strong *ngIf="mandant.preisPositionInSplan !== 'none'" class="menu-gesamtpreis">{{ menu.gesamtBetrag | euroPrice }}</strong>
                </div>
                <span class="menu-fehler"*ngIf="menu.error">{{ menu.error.msgId | speiseplan_fehler }}</span>
              </div>
            </ng-container>
          </div>
        </div>
      </mat-card>
    </div>

    </div>
    <div id="warenkorb-footer">
      <div id="warenkorb-gesamtpreis">
        <div id="gesamtPreis" *ngIf="mandant.preisPositionInSplan !== 'none'">
          <strong>Gesamtpreis <span>(inkl. MwSt.)</span>: </strong>
          <strong>{{ bestellungen.gesamtPreis | euroPrice }}</strong>
        </div>
        <div id="warenkorb-gesamtpreis-heading" [innerHTML]="warenkorb_titel">{{warenkorb_titel}}</div>
      </div>
      <div id="warenkorb-optionen">
        <button [disabled]="error" (click)="bestellung_commit()">
          <span>Zum genannten Preis bestellen</span>
        </button>
        <button (click)="bestellung_stornieren()">
          <span>Bestelländerung zurücksetzen</span>
        </button>
      </div>
    </div>
</div>

<div id="warenkorb-leer" *ngIf="bestellungen_anzahl == 0">
  <mat-icon>remove_shopping_cart</mat-icon>
</div>
