import {Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {RegistrierungService} from '../../../../../services/registrierung/registrierung.service';
import {PasswortRegelnService} from './passwortRegeln.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-formular-password-input',
  templateUrl: './formular-password-input.component.html',
  styleUrls: ['./formular-password-input.component.css']
})
export class FormularPasswordInputComponent implements OnInit, OnDestroy {

  @Input() public formGroup: UntypedFormGroup
  @Input() feldConfig
  @Input() tabIndex
  @Input() mandant
  @HostBinding('className') componentClass: string;
  public visibility: boolean = false
  public passwortKomplexZeigen: boolean = false
  public passwortRegelnValidierung
  public passwortRegelnSubscription: Subscription
  public passwortKomplexActive
  public validCounter

  constructor(
    public registrationService: RegistrierungService,
    public passwortRegelnService: PasswortRegelnService,
  ) { }

  ngOnInit(): void {
    this.componentClass = this.feldConfig.name;
    this.passwortRegelnService.erstelleRegelwerk(this.mandant);
    let passworComplex = this.mandant.sonderKonfiguration.API_1_5.passwordComplex
    this.passwortKomplexActive = passworComplex['require'].length > 0 || passworComplex['optional'].length > 0;
    this.passwortRegelnSubscription = this.passwortRegelnService.passwortRegelnObserver.subscribe((passwortRegelnValidierung) => {
      this.passwortRegelnValidierung = passwortRegelnValidierung;
      const minOptional = this.mandant?.sonderKonfiguration?.API_1_5?.passwordComplex?.minOptional || 0;
      const optionalValidCount = Object.values(this.passwortRegelnValidierung.optional).filter(Boolean).length;
      this.validCounter = Math.max(0, minOptional - optionalValidCount);
    });
  }

  ngOnDestroy() {
    this.passwortRegelnSubscription.unsubscribe()
  }

  public zeigePasswort(){
    if (this.visibility){
      this.visibility = false
    }else{
      this.visibility = true
    }
  }
}
