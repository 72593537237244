<div id="speiseplan-bestellung" *ngIf="config">
  <ng-container *ngIf="!gesperrt">
    <div class="speiseplan-bestellung-dashboard">
      <div class="bestellIcons">
        <img src="{{ bestell_regeln | bestellschlussicon }}" />
      </div>
        <div class="speiseplan-bestellung-optionen">
            <button
                *ngIf="config.mehrfachbestellbar && !menuSync"
                class="bad"
                [disabled]="(bestell_regeln.abbestellschluss && bestellung.mengeNeu == bestellung.mengeAlt) || bestell_regeln.min == bestellung.mengeNeu"
                (click)="bestellung_minus($event)"
            ><mat-icon>remove</mat-icon></button>
          <button
            *ngIf="config.mehrfachbestellbar && !menuSync"
            class="good"
            [disabled]="(bestell_regeln.bestellschluss && bestellung.mengeNeu == bestellung.mengeAlt) || bestell_regeln.max == bestellung.mengeNeu"
            (click)="bestellung_plus($event)"
          ><mat-icon>add</mat-icon></button>
            <input class="mehrfachbestellbar"
            *ngIf="config.mehrfachbestellbar && !menuSync"
                type="number"
                [(ngModel)]="bestellung.mengeNeu"
                (keypress)="validateNumberInput($event)"
                (change)="bestellung_mehrfach()"
                (focus)="fokusPruefung($event)"
                (blur)="fokusVerlassen(bestellung.mengeNeu, $event)"
                [placeholder]="placeholderZahl"
                [min]="bestell_regeln.min"
                [max]="bestell_regeln.max"
                [readOnly]="(bestell_regeln.abbestellschluss && bestell_regeln.bestellschluss) || (bestell_regeln.bestellschluss && bestellung.mengeNeu == bestellung.mengeAlt)"
                [class.disabled]="bestell_regeln.abbestellschluss && bestell_regeln.bestellschluss || !bestell_regeln.abbestellschluss && (bestell_regeln.bestellschluss && bestellung.mengeNeu == bestellung.mengeAlt)"
            />
            <div
              *ngIf="!config.mehrfachbestellbar || menuSync"
              (click)="bestellung_einzeln()"
              class="speiseplan-bestellung-einzeln"
              [class.disabled]="(bestell_regeln.abbestellschluss && bestell_regeln.bestellschluss) || (bestell_regeln.bestellschluss && bestellung.mengeAlt == 0)"
            >
              <mat-icon *ngIf="bestellung.mengeNeu > 0">checked</mat-icon>
            </div>
        </div>
    </div>
    <div class="speiseplan-bestellung-error" *ngIf="bestellung.error">{{ bestellung.error }}</div>
  </ng-container>
    <div id="gesperrt" *ngIf="gesperrt">
      <mat-icon id="speiseplanBestellungGesperrt">lock</mat-icon>
    </div>
</div>
