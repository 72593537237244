export class FlexLinkClass {
  public linkListe = {
    agb: '',
    datenschutz: '',
    widerruf: '',
    impressum: '',
    kontakt: '',
    kuendigung: '',
    faq: ''
  }

  public switchListe = {
    agb: 'AGB',
    datenschutz: 'Datenschutz',
    widerruf: 'Widerruf',
    impressum: 'Impressum',
    kontakt: 'Kontakt',
    kuendigung: 'Kündigung',
    faq: 'FAQ'
  }
}
