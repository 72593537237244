import {Injectable} from '@angular/core';
import {ContentBlockService} from '../../../../services/prozesse/contentBlock.service';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';

@Injectable()
export class FaqService {

  public faqAnzeige: BehaviorSubject<any> = new BehaviorSubject(false)
  public zweck

  constructor(
    private content_service: ContentBlockService,
    private router: Router,
  ) {
    if (environment.type == 'web'){
      this.zweck = 'Web_FAQ'
    } else {
      this.zweck = 'App_FAQ'
    }
    if (environment.mandant_id == '103716-ingolstadt'){
      this.faqAnzeige.next(true);
    }
  }

  faqAnzeigen(kunde){
    this.content_service.getContentBlock(this.zweck).subscribe(result => {
      //this.faqAnzeige.next(false)
      if (result){
        this.contentFilter(result, kunde)
      }
    })
  }

  contentFilter(inhalt, kunde){
    const contentListe = inhalt.split('_ende')
    contentListe.pop()
    if (kunde) {
      if (contentListe.length > 0) {
        this.faqAnzeige.next(true)
      } else {
        this.faqAnzeige.next(false)
      }
    } else {
      for (let auswahl of contentListe){
        if (auswahl.includes('_uneingeloggt')){
          this.faqAnzeige.next(true)
        }
      }
    }
  }
}

