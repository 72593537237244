<div id="schnellzugriff">
  <div id="schnellzugriffContent">
    <mat-toolbar [class.standalone]="!kunde">
      <ng-container *ngIf="kunde">
        <a routerLink="speiseplan-kunde">
          <mat-icon routerLinkActive="_active" aria-hidden="false" aria-label="Speiseplan">restaurant</mat-icon>
        </a>
        <a routerLink="warenkorb">
          <mat-icon id="warenkorbIcon" routerLinkActive="_active" [matBadge]="this.bestellungsBadge"
                    [matBadgeHidden]="!this.bestellungsBadge" matBadgeSize="small"
                    aria-hidden="false" aria-label="Warenkorb">shopping_cart</mat-icon>
        </a>
      </ng-container>

      <div *ngIf="hauptmenu" routerLink="menu" id="menu">
        <mat-icon routerLinkActive="_active" id="menu_icon" aria-hidden="false" aria-label="Hauptmenü">home
        </mat-icon>
      </div>
      <div *ngIf="!hauptmenu" [routerLink]="navigation.vorhergehende_seite" id="menu">
        <mat-icon routerLinkActive="_active" id="menu_icon_close" aria-hidden="false" aria-label="Hauptmenü schließen">close
        </mat-icon>
      </div>
      <a routerLink="bestellhistorie" *ngIf="kunde">
        <mat-icon routerLinkActive="_active" aria-hidden="false" aria-label="Bestellhistorie">date_range</mat-icon>
      </a>
      <expressStorno-icon *ngIf="kunde" [kunde]="kunde" [mandant]="mandant"></expressStorno-icon>
    </mat-toolbar>
  </div>
  <div id="schnellzugriffPlatzhalter"></div>
</div>
