import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {RegistrierungService} from '../../../../../services/registrierung/registrierung.service';
import {ContentBlockService} from '../../../../../services/prozesse/contentBlock.service';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-formular-freitext-input',
  templateUrl: './formular-freitext-input.component.html',
  styleUrls: ['./formular-freitext-input.component.css']
})
export class FormularFreitextInputComponent implements OnInit {
  @Input() public formGroup: UntypedFormGroup
  @Input() feldConfig
  @Input() tabIndex
  @HostBinding('className') componentClass: string;
  public freitextTitel

  constructor(
    public registrationService: RegistrierungService,
    public contentBlockService: ContentBlockService,
  ) { }

  ngOnInit(): void {
    this.componentClass = this.feldConfig.name;
    this.contentBlockPruefen()
  }

  contentBlockPruefen(){
    let titel = environment.type == 'web' ? 'Web_RegistrierungFreitext' : 'App_RegistrierungFreitext'
    this.contentBlockService.getContentBlock(titel).subscribe((result:any) => {
      if (result){
        this.freitextTitel = result
      } else {
        this.freitextTitel = this.feldConfig.text
      }
    })
  }
}
