<div id="erweiterteZustimmungZusammenfassung" [class.mobil]="environment.type !== 'web'">
  <div *ngIf="bestellerinnerung">
    <strong>Ich möchte die Bestellerinnerung nutzen.</strong>
    <span *ngIf="bestellerinnerung == 'true'">Ja</span>
    <span *ngIf="bestellerinnerung == 'false'">Nein</span>
  </div>

  <div *ngIf="erweiterteZustimmung1">
    <strong>Ich beauftrage die HOT Menü GmbH mit der Herstellung und Lieferung der ausgewählten Mittagsspeisen.</strong>
    <span *ngIf="erweiterteZustimmung1 == 'true'">Ja</span>
    <span *ngIf="erweiterteZustimmung1 == 'false'">Nein</span>
  </div>

  <div *ngIf="erweiterteZustimmung2">
    <strong>MDS Serviceleistung</strong>
    <span *ngIf="erweiterteZustimmung2 == 'true'">Ja</span>
    <span *ngIf="erweiterteZustimmung2 == 'false'">Nein</span>
  </div>
</div>
