<div id="vorauswahlEditorContainer" *ngIf="environment.type == 'web'">
  <div id="vorauswahlEditorTitelContainer">
    <strong id="vorauswahlEditorTitel">Menüvorauswahl bearbeiten</strong>
    <mat-icon mat-dialog-close>close</mat-icon>
  </div>
  <div id="editorContent">
    <div *ngFor="let menuOptionen of menu.optionen | keyvalue" class="vorauswahlEditorKomponenten" class="editorCard">
      <div class="editorTitel">
        <div id="vorauswahlEditorLabel">
          <strong *ngIf="menuOptionen.value.pos == 1">Hauptkomponente / Menü</strong>
          <strong *ngIf="menuOptionen.value.pos != 1">Komponente {{ menuOptionen.key - 1 }}</strong>
        </div>
      </div>
      <div id="editorMenuList">
        <div
          id="editorMenuItem"
          *ngFor="let menuId of menuOptionen.value['menueIds']"
          [class.disabled]="hauptkomponenteBereitsGewaehlt(buffer, menuId, menuOptionen.value['pos'], menu.menueNr)"
        >
          <div id="editorMenuItemBeschreibung">
            <strong [innerHTML]="menuId | menuVorauswahlMenuname:aktuelleMenuVorauswahl[data.tag.datum] | bbCode"></strong>
            <span [innerHTML]="menuId | menuVorauswahlMenutext:aktuelleMenuVorauswahl[data.tag.datum] | bbCode"></span>
          </div>
          <div id="editorMenuItemOption">
            <mat-checkbox
              #checkbox
              id="{{menu.menueNr}}-{{menuOptionen.value['pos']}}-{{menuId}}"
              class="editorMenuItemCheckbox"
              color="accent"
              [checked]="buffer.menues && buffer.menues[menu.menueNr] && buffer.menues[menu.menueNr][menuOptionen.value['pos']] == menuId"
              [disabled]="hauptkomponenteBereitsGewaehlt(buffer, menuId, menuOptionen.value['pos'], menu.menueNr)"
              (change)="aktualisiereVorauswahl($event, menu.menueNr, menuId, menuOptionen.value['pos'])"
            >
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="vorauswahlEditorOptionen">
    <button id="abbrechen" (click)="verwerfeVorauswahl()">Auswahl abbrechen</button>
    <button id="reset" (click)="setzeVorauswahlzurueck(menu.menueNr)">Auswahl zurücksetzen</button>
    <button id="speichern" (click)="speichereVorauswahl()">Auswahl speichern</button>
  </div>
</div>
