import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import {interval, Observable} from 'rxjs';
import { finalize } from "rxjs/operators";
import {NavigationEnd, Router } from "@angular/router";
import {LadeanzeigeService} from '../prozesse/ladeanzeige.service';

@Injectable()
export class LoadingScreenInterceptor implements HttpInterceptor {

  activeRequests = [];

  constructor(
    private router: Router,
    private ladeanzeigeService: LadeanzeigeService,
  ) {
    this.router.events.subscribe((event:any) => {
      if (event instanceof NavigationEnd){
        for (let request of this.activeRequests){
          if (request.origin !== this.router.url){
            this.entferneRequestAusArray(request)
          }
        }
      }
    })
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.activeRequests.length === 0) {
      if (!this.ladeanzeigeService.loadingOverlay.value){
        this.ladeanzeigeService.loadingOverlay.next(true);
      }
    }
    request['origin'] = this.router.url
    this.activeRequests.push(request);

    return next.handle(request).pipe(
      finalize(() => {
        this.entferneRequestAusArray(request)
        if (this.activeRequests.length === 0) {
          if (this.ladeanzeigeService.loadingOverlay.value){
            this.ladeanzeigeService.loadingOverlay.next(false)
          }
        }
      })
    )
  };

  public entferneRequestAusArray(request){
    const index = this.activeRequests.findIndex(element => element === request)
    this.activeRequests.splice(index, 1)
  }
}
