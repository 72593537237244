import {AfterViewInit, Component, OnInit} from '@angular/core';
import {MenuVorauswahlService} from '../../../obs.menuVorauswahl/services/menuVorauswahl.service';
import {ConfirmDialogComponent} from '../../../obs.shared/dialog/confirm-dialog/confirm-dialog.components';
import {MatDialog} from '@angular/material/dialog';
import * as moment from 'moment/moment';

@Component({
	selector: 'menuVorauswahl-seitenleiste',
	templateUrl: './menuVorauswahl.seitenleiste.component.html',
	styleUrls: ['./menuVorauswahl.seitenleiste.component.css'],
})
export class MenuVorauswahlSeitenleisteComponent implements OnInit, AfterViewInit {

  public mandant
  public tag
  public menu
  public neben

  constructor(
    private menuVorauswahlService: MenuVorauswahlService,
    private dialog: MatDialog,
	) { }

  ngOnInit() {}

  ngAfterViewInit() {
  }

  speichereVorauswahl(){
    let aktuelleMenuVorauswahl = this.menuVorauswahlService.menuVorauswahl.getValue()
    this.menuVorauswahlService.menuVorauswahlCommit(aktuelleMenuVorauswahl)
  }

  verwerfeVorauswahl(){
    let confirm = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "",
        message: `Sie haben noch offene Menüvorauswahlen. Möchten Sie trotzdem fortfahren?`,
        panelClass: 'dialog-container-bestellschluss',
      }
    }).afterClosed();

    confirm.subscribe((result) => {
      window.location.reload()
    })
  }

  public bestellzeiten(value): any {
    return moment.default(value).format('DD.MM.YYYY | HH:mm') + ' Uhr'
  }

  protected readonly Object = Object;
}
