import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router} from '@angular/router';
import { AppStateService } from 'services/app/app-state.service';
import {timer } from 'rxjs';
import { KundeService } from 'services/kunden/kunde.service';
import {ServiceWorkerService} from '../../../services/navigation/service-worker.service';
import {BestellungService} from '../../../services/bestellung/bestellung.service';
import {ConfirmDialogComponent} from '../../../submodul/obs.shared/dialog/confirm-dialog/confirm-dialog.components';
import { MatDialog } from '@angular/material/dialog';
import {RevisionService} from '../../../services/api/revision.service';

@Component({
    selector: 'mobil-toolbar',
    templateUrl: './mobil-toolbar.component.html',
    styleUrls: ['./mobil-toolbar.component.css'],
})
export class MobilToolbarComponent implements OnInit, OnDestroy {

  @ViewChild('sidemenu_icon') sidemenu_icon: ElementRef
  @ViewChild('refresh_icon') refresh_icon: ElementRef
  @ViewChild('message_icon') message_icon: ElementRef
  public page_name: string
  public page_navigation: string
  public login: any
  public messages = false
  public icon

  constructor(
    public app_state_service: AppStateService,
    private router: Router,
    private kunde_service: KundeService,
    private serviceWorker: ServiceWorkerService,
    private bestellungsService: BestellungService,
    private dialog: MatDialog,
    private revisionsService: RevisionService,
  ) {}

  ngOnInit() {
    this.kunde_service.update.subscribe(() => {
      this.kunde_service._kunde_get().subscribe(login => {
        this.login = login
      })
    })
    this.app_state_service.page_name.subscribe(
      page_name => { this.page_name = page_name }
    )
    this.app_state_service.page_icon.subscribe((value) => {
      this.icon = value
    })
    this.app_state_service.page_menu.subscribe(
      page_header_icon => {
        if(page_header_icon){
          this.app_state_service.side_menue = true;
          if (this.app_state_service.page_name.getValue() == "Speiseplan" && this.message_icon){
            this.refresh_icon.nativeElement.classList.add('header-content-icons')
            this.message_icon.nativeElement.classList.add('header-content-icons')
          }
          else if (this.app_state_service.page_name.getValue() != "Speiseplan" && this.message_icon) {
            this.message_icon.nativeElement.classList.add('header-content-icons')
          }
          else {

          }
        }else{
          this.app_state_service.side_menue = false;
            timer(500).subscribe(() => {
              if (this.app_state_service.page_name.getValue() == "Speiseplan" && this.message_icon){
                this.refresh_icon.nativeElement.classList.remove('header-content-icons')
                this.message_icon.nativeElement.classList.remove('header-content-icons')
              }
              else if (this.app_state_service.page_name.getValue() != "Speiseplan" && this.message_icon){
                this.message_icon.nativeElement.classList.remove('header-content-icons')
              }
              else {

              }
            })
        }}
    )
    this.app_state_service.page_navigation.subscribe(
      page_navigation => { this.page_navigation = page_navigation }
    )
  }

  ngOnDestroy(){
    this.app_state_service.page_menu.unsubscribe();
    this.app_state_service.page_name.unsubscribe();
    this.app_state_service.page_navigation.unsubscribe();
    this.kunde_service.update.unsubscribe()
  }

  public refresh(){
    this.revisionsService.revision_mandant('mandant/get')
    if (this.bestellungsService._bestellungen_anzahl.getValue() > 0){
      this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: "",
          message: `Sie haben offene Bestellvorgänge im Warenkorb. Möchten Sie die Ansicht trotzdem neuladen?`,
          panelClass: 'dialog-container-bestellschluss',
        }
      }).afterClosed().subscribe((result) => {
        if (result) {
          this._refresh()
        }
      })
    }else{
      this._refresh()
    }
  }

  private _refresh(){
    let aktuelleUrl = this.router.url
    this.serviceWorker.serviceWorkerUpdate()
    this.router.navigateByUrl('/menu', {skipLocationChange: true}).then(() => {
      document.getElementById('refreshIcon').classList.add('rotate-center')
      setTimeout(() => {
        document.getElementById('refreshIcon').classList.remove('rotate-center')
      }, 1000)
      this.router.navigateByUrl(aktuelleUrl)
    });
  }

}
