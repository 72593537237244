import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {PipeModule} from "../../../pipe/pipe.module";
import {RegistrierungErfolgreichComponent} from './registrierung-erfolgreich/registrierung-erfolgreich.component';
import {RegistrierungFormularComponent} from './registrierung-formular/registrierung-formular.component';
import {RegistrierungInfoComponent} from './registrierung-info/registrierung-info.component';
import {RegistrierungVertragComponent} from './registrierung-vertrag/registrierung-vertrag.component';
import {RegistrierungZusammenfassungComponent} from './registrierung-zusammenfassung/registrierung-zusammenfassung.component';
import {RegistrierungZwischenseiteComponent} from './registrierung-zwischenseite/registrierung-zwischenseite.component';
import {MaterialModule} from '../../../application/web/material.module';
import {DirectiveModule} from '../../../directives/directives.module';
import {MatExpansionModule} from '@angular/material/expansion';
import {BrowserModule} from '@angular/platform-browser';
import {SharedModule} from '../../obs.shared/shared.module';
import {RegistrierungsEinrichtungBezeichnung} from '../directive/registrierungsEinrichtungBezeichnung';
import {RegistrierungZusatzfelderComponent} from './registrierungZusatzfelder/registrierungZusatzfelder.component';
import {RegistrierungZusatzfelderService} from './registrierungZusatzfelder/registrierungZusatzfelder.service';
import {ZusatzZustimmungComponent} from './registrierungZusatzfelder/felder/zusatzZustimmung/zusatzZustimmung.component';
import {
  CheckboxBestellerinnerungComponent
} from './registrierungZusatzfelder/felder/checkboxBestellerinnerung/checkboxBestellerinnerung.component';
import {
  ZusatzfelderZusammenfassungComponent
} from './registrierungZusatzfelder/zusatzfelderZusammenfassung/zusatzfelderZusammenfassung.component';

@NgModule({
  declarations: [
    RegistrierungErfolgreichComponent,
    RegistrierungFormularComponent,
    RegistrierungInfoComponent,
    RegistrierungVertragComponent,
    RegistrierungZusammenfassungComponent,
    RegistrierungZwischenseiteComponent,
    RegistrierungsEinrichtungBezeichnung,
    RegistrierungZusatzfelderComponent,
    CheckboxBestellerinnerungComponent,
    ZusatzZustimmungComponent,
    ZusatzfelderZusammenfassungComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PipeModule,
    DirectiveModule,
    MatExpansionModule,
    MatExpansionModule,
    BrowserModule,
    MatExpansionModule,
    SharedModule,
  ],
  exports: [
    RegistrierungErfolgreichComponent,
    RegistrierungFormularComponent,
    RegistrierungInfoComponent,
    RegistrierungVertragComponent,
    RegistrierungZusammenfassungComponent,
    RegistrierungZwischenseiteComponent,
    RegistrierungsEinrichtungBezeichnung,
  ],
  providers: [
    RegistrierungZusatzfelderService
  ]
})
export class RegistrierungModule { }
