import {AfterViewInit, Directive, ElementRef, Input, ViewContainerRef} from '@angular/core';
import {FormularTextInputComponent} from '../../src/submodul/obs.shared/fragmente/formular-felder/formular-text-input/formular-text-input.component';
import tabulator_config from 'configs/registrierung-tabulatoren.json';
import {FormularSelectInputComponent} from '../submodul/obs.shared/fragmente/formular-felder/formular-select-input/formular-select-input.component';
import {
  FormularFreitextInputComponent
} from '../../src/submodul/obs.shared/fragmente/formular-felder/formular-freitext-input/formular-freitext-input.component';
import {
  FormularCheckboxInputComponent
} from '../../src/submodul/obs.shared/fragmente/formular-felder/formular-checkbox-input/formular-checkbox-input.component';
import {FormularDateInputComponent} from '../../src/submodul/obs.shared/fragmente/formular-felder/formular-date-input/formular-date-input.component';
import {
  FormularPasswordInputComponent
} from '../submodul/obs.shared/fragmente/formular-felder/formular-password-input/formular-password-input.component';

import { RegistrierungsEinrichtungRegel } from "./registrierungRegeln/registrierungsEinrichtungRegel";
import { RegistrierungsLeistungsBeginnRegel } from './registrierungRegeln/registrierungsLeistungsBeginnRegel';

@Directive({
  selector: '[appRegistrierungFormularRenderer]'
})
export class RegistrierungFormularRendererDirective implements AfterViewInit{

  @Input() vertragsId:string
  @Input() feldConfig
  @Input() formGroup
  @Input() mandant
  private feldTypes = {
    text: FormularTextInputComponent,
    select: FormularSelectInputComponent,
    textarea: FormularFreitextInputComponent,
    checkbox: FormularCheckboxInputComponent,
    date: FormularDateInputComponent,
    passwort: FormularPasswordInputComponent,
  }
  private componentStacks = {}

  constructor(
    private el: ElementRef,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngAfterViewInit() {
    this.renderingDefault()
    this.renderingChanges()
  }

  renderingDefault(){
    for (let feldName in this.feldConfig){
      if (this.feldConfig[feldName].active){
        let value
        tabulator_config.filter((element => {
          if (element.name == this.feldConfig[feldName].name)
            value = element.id
        }))
        const componentRef: any = this.viewContainerRef.createComponent(this.feldTypes[this.feldConfig[feldName].type])
        componentRef.instance.formGroup = this.formGroup
        componentRef.instance.feldConfig = this.feldConfig[feldName]
        componentRef.instance.tabIndex = value
        componentRef.instance.mandant = this.mandant
        this.componentStacks[feldName] = componentRef
      }
    }
  }

  renderingChanges(){
    if (
      this.mandant.registrierFormen[this.vertragsId].eingabeFelder.einrichtung_nameEinrichtung.options.length > 0
      && this.componentStacks['einrichtung_nameEinrichtung']
    ) {
      const regristrierungsEinrichtungRegel = new RegistrierungsEinrichtungRegel(this.viewContainerRef, this.mandant)
      regristrierungsEinrichtungRegel.init(this.componentStacks, this.formGroup, this.feldConfig)
    }
    if (this.mandant.sonderKonfiguration.leistungsbeginnLand && this.componentStacks['leistungsBeginn']){
      const regristrierungsLeistungsBeginnRegel = new RegistrierungsLeistungsBeginnRegel(this.viewContainerRef, this.mandant)
      regristrierungsLeistungsBeginnRegel.init(this.componentStacks, this.formGroup, this.feldConfig, 'land')
    }
    if (this.mandant.sonderKonfiguration.leistungsbeginnDatum && this.componentStacks['leistungsBeginn']){
      if (this.mandant.sonderKonfiguration.leistungsbeginnDatum == this.vertragsId){
        const regristrierungsLeistungsBeginnRegel = new RegistrierungsLeistungsBeginnRegel(this.viewContainerRef, this.mandant)
        regristrierungsLeistungsBeginnRegel.init(this.componentStacks, this.formGroup, this.feldConfig, 'datum')
      }
    }
  }
}
