import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DbReaderService } from 'services/db-reader/db-reader.service';
import { map, switchMap } from 'rxjs/operators';
import {of, timer, zip} from 'rxjs';
import { KundeService } from '../kunden/kunde.service';
import { MitteilungsService } from '../prozesse/mitteilung.service';
import { Router } from '@angular/router';
import { AppStateService } from 'services/app/app-state.service';
import { BestellungService } from '../bestellung/bestellung.service';
import { environment } from 'environments/environment';
import {MandantenService} from '../mandanten/mandanten.service';
import {StateService} from '../app/state.service';
import {BenachrichtigungService} from '../prozesse/benachrichtigung.service';
import {ContentBlockService} from '../prozesse/contentBlock.service';
import {MitteilungenService} from '../../submodul/obs.shared/componenten/mitteilungen/mitteilungen.service';

@Injectable()
export class RevisionService {

  private connection = {
    url: environment.url,
    version: 'API_1_4'
  }

  constructor(
    private http: HttpClient,
    private db: DbReaderService,
    private mandant_service: MandantenService,
    private kunden_service: KundeService,
    private mitteilungs_service: MitteilungsService,
    private benachrichtigung_service: BenachrichtigungService,
    private app_state_service: AppStateService,
    private router: Router,
    private bestellungen_service: BestellungService,
    private stateService: StateService,
    private contentBlockService: ContentBlockService,
    private mitteilungenService: MitteilungenService,
  ) { }

  public revision_mandant(command) {
    this.db.get('aktiv', 'Mandant').subscribe(_id => {
      this.db.get(_id, 'Mandant').subscribe(mandant => {
        if (mandant) {
          let payload = {
            command: 'mandant/revisionsCheck',
            client: environment.type,
            parameter: {
              mandantId: _id,
              revision: mandant.revision
            }
          }
          this.http.post(this.connection.url + '/public', payload).subscribe((revision: any) => {
            if (revision != true) {
              this.db.add(revision, _id, 'Mandant')
              this.contentBlockService.contentBlockAnlegen(revision.sonderKonfiguration.API_1_5.contentBlockListe)
              this.stateService.mandant.next(revision)
              this.db.add(_id, 'aktiv', 'Mandant')
              this.revision_mandant_kunde_check(_id)
              this.mitteilungs_service.setMitteilung(3010)
              if (window.localStorage.getItem('formular')){
                window.localStorage.removeItem('formular')
              }
            } else {
              this.mandant_service.mandant_get().subscribe(mandant => {
                this.contentBlockService.contentBlockRevision(mandant.sonderKonfiguration.API_1_5.contentBlockListe)
              })
            }
            this.mitteilungenService.holeMitteilungen()
          })
        }
      })
    })
  }

  private revision_mandant_kunde_check(mandantId) {
    if (environment.type == 'mobil') {
      this.db.all('User', 'value')
        .pipe(map(kunden => { return kunden.filter(element => typeof element == 'object') }))
        .subscribe(kunden => {
          for (let kunde of kunden) {
            this.http.post(this.connection.url + '/relogin', {
              token: localStorage.getItem(kunde.kundeId),
              client: environment.type,
              parameter: { mandantId: mandantId }
            }).subscribe((aktualisierterKunde: any) => {
              this.db.delete(kunde.kundeId)
              if (kunde.kundeId !== aktualisierterKunde.kundeId){
                localStorage.removeItem(kunde.kundeId)
              }
              if (kunde.kundeId === localStorage.getItem('active')) {
                this.benachrichtigung_service.benachrichtigung_aktualisieren(aktualisierterKunde, true, kunde)
                this.db.add(aktualisierterKunde.kundeId, 'active', 'User')
              }
              localStorage.setItem('active', aktualisierterKunde.kundeId)
              this.db.add(aktualisierterKunde, aktualisierterKunde.kundeId, 'User')
            })
          }
          this.mitteilungs_service.set_snackbar('3010', 5000)
          this.bestellungen_service.bestellungen_leeren()
          this.router.navigateByUrl('/')
        })
    } else {
      if (localStorage.getItem('active')){
        this.db.clear('User').subscribe(() => {
          localStorage.removeItem(localStorage.getItem('active'))
          localStorage.removeItem('active')
          localStorage.removeItem('benachrichtigung')
          this.kunden_service.update.next(true)
          this.kunden_service.kunde = false
          this.bestellungen_service.bestellungen_leeren()
          this.router.navigateByUrl('/')
          timer(1000).subscribe(() => {
            this.mitteilungs_service.setMitteilung('3010')
            this.mitteilungenService.holeMitteilungen()
          })
        })
      }
    }
  }

  public revision_kunde(command) {
    let kunde = this.db.get('active', 'User').pipe(switchMap((id) => id ? this.db.get(id, 'User') : of(false)))
    let mandant = this.db.get('aktiv', 'Mandant')
    zip(kunde, mandant)
      .pipe(map((_zip) => { return { kunde: _zip[0], mandant: _zip[1] } }))
      .subscribe((_zip) => {
        if (_zip.kunde) {
          this.http.post(this.connection.url + '/call', {
            command: 'kunde/revisionsCheckAPI_1_5_1',
            client: environment.type,
            token: localStorage.getItem(localStorage.getItem('active')),
            parameter: {
              mandantId: _zip.mandant,
              kundenId: _zip.kunde.kundeId,
              revision: _zip.kunde.revision
            }
          }).subscribe((response: any) => {
            if (response != 'true') {
              if (response.order4NewPw) {
                this.kunden_service.kunde_logout(response.kundeId).subscribe(() => {
                  this.mitteilungs_service.set_snackbar('8108', 5000)
                  this.router.navigateByUrl('/')
                })
              } else {
                this.db.add(response, response.kundeId, 'User')
                this.kunden_service.update.next(true)
                this.mitteilungenService.holeMitteilungen()
              }
              if (localStorage.getItem('registrierungForm')){
                localStorage.removeItem('registrierungForm')
              }
            }
          })
        }
      })
    }
  //}
}
