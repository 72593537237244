import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AnkuendigungService} from '../ankuendigung.service';

@Component({
  selector: 'app-ankuendigung-popup',
  templateUrl: './ankuendigung-popup.component.html',
  styleUrls: ['./ankuendigung-popup.component.css']
})
export class AnkuendigungPopupComponent implements OnInit {

  public ankuendigungsContent:string

  constructor(
    public dialogRef: MatDialogRef<AnkuendigungPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private ankuendigungsService: AnkuendigungService,
  ) { }

  ngOnInit(): void {
    this.ankuendigungsContent = this.ankuendigungsService.ankuendigungsPopupContent
  }
}
