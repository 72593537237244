import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AppStateService } from 'services/app/app-state.service';
import { timer } from 'rxjs';
import { MandantenService } from 'services/mandanten/mandanten.service';

@Component({
  selector: 'mobil-overview',
  templateUrl: './mobil-speiseplan-overview.component.html',
  styleUrls: ['./mobil-speiseplan-overview.component.css']
})

export class MobilSpeiseplanOverviewComponent implements OnInit, AfterViewInit {

    public mandant: any;
    public message: any;

    constructor(
      public state_service: AppStateService,
      private mandant_service: MandantenService,
    ) {
      this.state_service.set_page_name('Speiseplanübersicht');
      this.state_service.set_seitenname('speiseplan-overview');
      this.state_service.set_page_navigation('normal');
    }

  ngOnInit() {
  this.mandant_service.mandant_get().subscribe(
    mandant => {
      this.mandant = mandant
  })
  }
  ngAfterViewInit(){
    timer(300).subscribe(() => {
      let links:any = document.getElementsByClassName("mobile-overview-link")
      for (let link of links){
        link.classList.add('flip-in')
      }
    })
  }
}
