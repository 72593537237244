import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { BestellungService } from 'services/bestellung/bestellung.service';
import { IBestellzeitraum } from 'services/interface/bestellung.interface';
import { KundeService } from 'services/kunden/kunde.service';
import { AppStateService } from 'services/app/app-state.service';
import { MitteilungsService } from 'services/prozesse/mitteilung.service';
import {MatDialog} from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../submodul/obs.shared/dialog/confirm-dialog/confirm-dialog.components';
import { MandantenService } from 'services/mandanten/mandanten.service';
import {SpeiseplanDetailsComponent} from '../../../submodul/obs.speiseplan/einzelbesteller/speiseplanDetails/speiseplanDetails.component';
import {SpeiseplanService} from '../../../submodul/obs.speiseplan/services/speiseplan.service';
import {environment} from '../../../environments/environment';
import {ApiCallService} from '../../../services/api/api-call.service';
import {ContentBlockService} from '../../../services/prozesse/contentBlock.service';
import {zip} from 'rxjs';
import {SpeiseplanEinzelbestellerService} from '../../../submodul/obs.speiseplan/services/speiseplan-einzelbesteller.service';

@Component({
  selector: 'mobil-warenkorb',
  templateUrl: './mobil-warenkorb.component.html',
  styleUrls: ['./mobil-warenkorb.component.css'],
})
export class MobilWarenkorbComponent implements OnInit, AfterViewInit {

  public bestellungen: IBestellzeitraum
  public bestellungen_meta
  public bestellungen_anzahl
  public speiseplanKunde
  public menutexte
  public mandant
  public kunde
  public warenkorb_titel
  public error = false

  constructor(
    private apiCallService: ApiCallService,
    private speiseplanService: SpeiseplanService,
    private bestellungen_service: BestellungService,
    private mandant_service: MandantenService,
    private kunde_service: KundeService,
    private mitteilungs_service: MitteilungsService,
    private router: Router,
    private state_service: AppStateService,
    private dialog: MatDialog,
    private content_service: ContentBlockService,
    private speiseplanEinelbestellerService: SpeiseplanEinzelbestellerService,
  ) {
    this.state_service.set_page_name('Warenkorb');
    this.state_service.set_seitenname('warenkorb')
    this.state_service.set_page_navigation('normal');
  }

  ngOnInit() {
    this.bestellungen_meta = this.bestellungen_service._bestellungen_meta
    this.bestellungen_anzahl = this.bestellungen_service._bestellungen_anzahl.getValue()
    zip(
      this.mandant_service.mandant_get(),
      this.kunde_service._kunde_get()
    ).subscribe((response) => {
      this.mandant = response[0]
      this.kunde = response[1]
      if (Object.keys(this.bestellungen_service._bestellungen.tage).length > 0) {
        this.bestellungen_service.bestellung_validate(this.kunde.bestellerId)
          .pipe(map(validation => { return <any>validation }))
          .subscribe(
            (validation) => {
              if (validation.kunden[this.kunde.bestellerId].error){
                this.mitteilungs_service.set_snackbar(validation.kunden[this.kunde.bestellerId].error.msgId, 5000)
                this.error = true
              }
              this.bestellungen = validation.kunden[this.kunde.bestellerId]
              this.speiseplanService.speiseplan_kunde(this.kunde.bestellerId, this.bestellungen.von, this.bestellungen.bis).subscribe(speiseplanKunde => {
                this.speiseplanKunde = speiseplanKunde
              })
              this.bestellungen_meta = this.bestellungen_service._bestellungen_meta
              this.bestellungen_anzahl = this.bestellungen_service._bestellungen_anzahl.getValue()
              this.menutexte = this.bestellungen_service._menutexte
              console.log(this.menutexte)
            },
            (error) => { }
          )
      }
    })
  }

  ngAfterViewInit() {
    this.warenkorb_titel_abrufen()
  }

  public bestellung_commit() {
    this.bestellungen_service.bestellung_commit(this.kunde.bestellerId).subscribe((apiResponse:any) => {
      let zeitraum = { von: this.bestellungen.von, bis: this.bestellungen.bis }
      this.bestellungen_service.bestellungen_leeren()
      this.bestellungen_anzahl = this.bestellungen_service._bestellungen_anzahl.getValue()
      this.bestellungen = this.bestellungen_service._bestellungen
      this.bestellungen_meta = this.bestellungen_service._bestellungen_meta
      this.menutexte = this.bestellungen_service._menutexte
      this.speiseplanEinelbestellerService.bearbeitet.next(false)
      this.mitteilungs_service.set_snackbar('8401', 2000)
      this.router.navigate(['bestellhistorie', { zeitraum: JSON.stringify(zeitraum) }])
    }, (error) => { })
  }

  public bestellung_stornieren() {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Benutzer entfernen",
        message: `Möchten Sie alle Bestelländerungen aus dem Warenkorb entfernen?`,
        panelClass: 'dialog-container-bestellschluss',
      }
    }).afterClosed().subscribe(result => {
      if (result){
        this.bestellungen_service.bestellungen_leeren()
        this.bestellungen_anzahl = this.bestellungen_service._bestellungen_anzahl.getValue()
        this.bestellungen = this.bestellungen_service._bestellungen
        this.bestellungen_meta = this.bestellungen_service._bestellungen_meta
        this.menutexte = this.bestellungen_service._menutexte
        this.speiseplanEinelbestellerService.bearbeitet.next(false)
        this.mitteilungs_service.set_snackbar('8201', 2000)
      }
    })
  }

  public bestellungDetails (menu) {
    const matDialogRef = this.dialog.open(SpeiseplanDetailsComponent, { width: '90%' });
    const menuText = this.speiseplanKunde.menuetexte[menu.menueTextId]
    menuText['allergene'] = menuText['allergeneIds']
    menuText['inhaltsstoffe'] = menuText['inhaltsstoffeIds']
    menuText['zusatzstoffe'] = menuText['zusatzstoffeIds']
    matDialogRef.componentInstance.menu = menuText
    matDialogRef.componentInstance.mandant = this.mandant
  }

  public warenkorb_titel_abrufen(){
    this.content_service.getContentBlock('App_WarenkorbTitel').subscribe(result => {
      if (result){
        this.warenkorb_titel = result
      } else {
        this.warenkorb_titel = 'Auf Grundlage des Verköstigungsvertrages'
      }
    })
  }
}
