import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {zip} from 'rxjs';
import {MandantenService} from '../mandanten/mandanten.service';
import {KundeService} from '../kunden/kunde.service';

@Injectable()
export class StartupNavigationGuard  {
  constructor(
    private router: Router,
    private mandantService: MandantenService,
    private kundenService: KundeService
  ) { }

  public canActivate(): boolean {
    zip(
      this.mandantService.mandant_get(),
      this.kundenService._kunde_get()
    ).subscribe((result) => {
      if (!result[1]){
        this.mandantStartseite(result[0])
      }else{
        this.kundeStartseite(result[0])
      }
    })
    return true;
  }

  private mandantStartseite(mandant){
    let navigation = mandant.navigation_root_default
    if (navigation == 'home') {
      this.router.navigateByUrl('/home')
    } else if (navigation == 'login') {
      this.router.navigateByUrl('/login')
    } else {
      if (Object.keys(mandant.speisePlanIds).length < 4){
        this.router.navigateByUrl('/speiseplan-mandant/' + Object.values(mandant.speisePlanIds)[0]['splanId'])
      } else {
        this.router.navigateByUrl('/speiseplan-overview')
      }
    }
  }

  private kundeStartseite(mandant){
    let navigation = mandant.navigation_logged_in_default
    if (navigation == 'menu') {
      this.router.navigateByUrl('/speiseplan-kunde')
    }
    if (navigation == 'home') {
      this.router.navigateByUrl('/home')
    }
  }
}
