import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from 'application/pwa/app.component';
import { MobilModule } from 'components/mobil/mobil.module';
import { RegistrierungModule } from '../../submodul/obs.registrierung/component/registrierung.module';
import { RoutingModule } from './routing.module';
import { RouterModule } from '@angular/router';
import { ServicesModule } from 'services/services.module';
import { MaterialModule } from './material.module';
import { ApiCallService } from 'services/api/api-call.service';
import { DbReaderService } from 'services/db-reader/db-reader.service';
import { MandantenService } from 'services/mandanten/mandanten.service';
import { environment } from 'environments/environment';
import {BehaviorSubject, timer} from 'rxjs';
import { ServiceWorkerModule } from '@angular/service-worker';
import {ServiceWorkerService} from 'services/navigation/service-worker.service';
import {PipeModule} from '../../pipe/pipe.module';
import {SharedModule} from '../../submodul/obs.shared/shared.module';
import {LoadingScreenInterceptor} from '../../services/interceptors/ladeanzeige.interceptor';
import {ContentBlockService} from '../../services/prozesse/contentBlock.service';
import {PwaInstallationComponent} from '../../submodul/obs.shared/dialog/pwaInstallation/pwaInstallation.component';

export function init_mandant(api: ApiCallService, db: DbReaderService, mandant_s: MandantenService, contentblock: ContentBlockService) {
  return () => {
    return new Promise((resolve) => {
      db.get('aktiv', 'Mandant').subscribe(_id => {
        if (!_id) {
          const parameter = { mandantId: environment.mandant_id }
          api.request('/public', 'mandant/get', parameter).subscribe((mandant: any) => {
            mandant_s.mandant_id = new BehaviorSubject(environment.mandant_id)
            contentblock.contentBlockRevision(mandant.sonderKonfiguration.API_1_5.contentBlockListe)
            db.add(mandant, environment.mandant_id, 'Mandant')
            db.add(environment.mandant_id, 'aktiv', 'Mandant')
            timer(500).subscribe(() => resolve(true))
          })
        } else {
          mandant_s.mandant_id = new BehaviorSubject(_id)
          resolve(true)
        }
      })
    });
  }
}

@NgModule({
    declarations: [
      AppComponent,
      PwaInstallationComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MobilModule,
        RouterModule,
        RoutingModule,
        ServicesModule,
        MaterialModule,
        PipeModule,
        RegistrierungModule,
        SharedModule,
        ServiceWorkerModule.register('./ngsw-worker.js', {
          registrationStrategy: 'registerWhenStable:30000',
          scope: '/mobil/'

        })
    ],
    providers: [
      ServiceWorkerService,
      { provide: APP_INITIALIZER, useFactory: init_mandant, deps: [ApiCallService, DbReaderService, MandantenService, ContentBlockService], multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: LoadingScreenInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
