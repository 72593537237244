import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class PasswortRegelnService {

  public regelwerk
  public regelwerkValidierung = {
    minOptional: {},
    required: {},
    optional: {}
  }
  public passwortRegelnObserver = new BehaviorSubject({})

  constructor() {
  }

  public erstelleRegelwerk(mandant){
    this.regelwerk = mandant.sonderKonfiguration.API_1_5.passwordComplex
    this.regelwerk.require.forEach(element => {
      this.regelwerkValidierung['required'][element.rule] = false
    })
    this.regelwerk.optional.forEach(element => {
      this.regelwerkValidierung['optional'][element.rule] = false
    })
    this.regelwerkValidierung['minOptional'] = this.regelwerk.minOptional
    this.passwortRegelnObserver.next(this.regelwerkValidierung)
  }

  public validiereRegelwerk(event){
    this.regelwerk.require.forEach(element => {
      const regex = new RegExp(element.rule)
      this.regelwerkValidierung['required'][element.rule] = regex.test(event.target.value)
    })
    this.regelwerk.optional.forEach(element => {
      const regex = new RegExp(element.rule)
      this.regelwerkValidierung['optional'][element.rule] = regex.test(event.target.value)
    })
    this.passwortRegelnObserver.next(this.regelwerkValidierung)
  }

  public returnRegelwerk(password: string): boolean {
    this.regelwerk.require.forEach(element => {
      const regex = new RegExp(element.rule);
      this.regelwerkValidierung['required'][element.rule] = regex.test(password);
    });

    this.regelwerk.optional.forEach(element => {
      const regex = new RegExp(element.rule);
      this.regelwerkValidierung['optional'][element.rule] = regex.test(password);
    });

    const allRequiredValid = Object.values(this.regelwerkValidierung.required).every(Boolean);
    const optionalValidCount = Object.values(this.regelwerkValidierung.optional).filter(Boolean).length;
    const optionalValid = optionalValidCount >= this.regelwerk.minOptional;
    return allRequiredValid && optionalValid;
  }


}
