<div id="expressStornoDialogContainer" *ngIf="apiZeitraum && data && speiseplanBestellung" [class.wba]="environment.type == 'mobil'">
  <div id="expressStornoDialogHeader">
    <strong>Express-Storno</strong>
    <mat-icon mat-button mat-dialog-close>close</mat-icon>
  </div>
  <div id="expressStornoDialogContent">
    <zeitraumPicker
                    [zeitraum]="apiZeitraum"
                    [speiseplanKunde]="data.kunde"
                    [speiseplanBestellung]="speiseplanBestellung"
                    (zeitraum_update)="sucheBestellungen($event)"
                    #zeitraumPicker>
    </zeitraumPicker>
  </div>
  <div id="expressStornoDialogErrorMsg" *ngIf="errorMsg" [class.scale-in-center]="errorMsg" [class.wbaError]="mobil">
    <span><strong>6031:</strong> Für den angegebenen Zeitraum sind keine Abbestellungen möglich.</span>
  </div>
  <div id="expressStornoDialogFooter" [class.wba]="mobil">
    <button mat-button mat-dialog-close id="schließen">Express-Storno abbrechen</button>
    <button mat-button id="bestaetigen" (click)="freischalten ? expressStonobestaetigen() : false" [class.disabled]="!freischalten">Express-Storno bestätigen</button>
  </div>
</div>
