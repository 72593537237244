<div *ngIf="mandant" id="speiseplanMandantDialog">
  <speiseplan-kalender
    #speiseplan_kalender
    [mandant]="mandant"
    [apiZeitraum]="apiZeitraum"
    (zeitraum_update)="speiseplan_laden($event)"
  ></speiseplan-kalender>

  <div appSpeiseplanLeer *ngIf="speiseplan" [speiseplanMenu]="speiseplan.menu" class="splanLeerContainer"></div>

  <div id="speiseplan-kunde" *ngIf="speiseplan">
    <mat-accordion multi>
      <mat-expansion-panel *ngFor="let tag of speiseplan.menu | keyvalue" [expanded]="speiseplan.menu | speiseplanAufklappen" class="speiseplanWba">
        <mat-expansion-panel-header class="labelHeaderCard">
          <speiseplanTagLabel class="splanLabel" [speiseplan]="speiseplan" [tag]="tag" [darstellung]="darstellung"></speiseplanTagLabel>
        </mat-expansion-panel-header>
        <speiseplanTagContent
          speiseplanTag
          [speiseplan_tag]="tag"
          [darstellung]="darstellung"
          [modus]="modus"
          [mandant]="mandant"
          [kunde]="kunde"
          [tag]="tag"
          [speiseplan]="speiseplan"
        ></speiseplanTagContent>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

</div>
