import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {RechnungenAnfordernComponent} from './rechnungenAnfordern/rechnungenAnfordern.component';
import {environment} from '../../../../environments/environment';
import {AppStateService} from '../../../../services/app/app-state.service';
import {zip} from 'rxjs';
import {MandantenService} from '../../../../services/mandanten/mandanten.service';
import {KundeService} from '../../../../services/kunden/kunde.service';

@Component({
  selector: 'app-rechnungen',
  templateUrl: './rechnungen.component.html',
  styleUrls: ['./rechnungen.component.css']
})
export class RechnungenComponent implements OnInit {

  public mandant
  public kunde
  public rechnungen: Array<any>
  public dataSource: MatTableDataSource<any> = new MatTableDataSource();
  public displayedColumns: string[] = ['datum', 'zeitraum', 'nr', 'betrag', 'offen', 'anfordern']

  constructor(
    private dialog: MatDialog,
    private state_service: AppStateService,
    private mandantService: MandantenService,
    private kundenService: KundeService,
  ) {
    this.state_service.set_page_name('Rechnungen');
    this.state_service.set_seitenname('rechnungen')
    this.state_service.set_page_navigation('normal');
  }

  ngOnInit() {
    zip(
      this.mandantService.mandant_get(),
      this.kundenService.kunde_get(),
    ).subscribe(apiResponse => {
      this.mandant = apiResponse[0]
      this.kunde = apiResponse[1]
      this.rechnungen = Object.values(this.kunde.rechnungsdaten)
      let test:any = Object.values(this.kunde.rechnungsdaten)
      this.dataSource = test
    })
  }

  public rechnungAnfordern(rechnungsNr: string){
    this.dialog.open(RechnungenAnfordernComponent, {
      data: {mandant: this.mandant, rechnungsNr: rechnungsNr},
    })
  }

  protected readonly environment = environment;
}
