import {AfterViewInit, Component, ViewChild, ViewContainerRef} from '@angular/core';
import * as berechtigungsListe from './zusatzfelderBerchtigungsliste.config.json'
import {environment} from '../../../../environments/environment';
import {CheckboxBestellerinnerungComponent} from './felder/checkboxBestellerinnerung/checkboxBestellerinnerung.component';
import {ZusatzZustimmungComponent} from './felder/zusatzZustimmung/zusatzZustimmung.component';

@Component({
  selector: 'registrierungZusatzfelder',
  templateUrl: './registrierungZusatzfelder.component.html',
  styleUrls: ['./registrierungZusatzfelder.component.css'],
})
export class RegistrierungZusatzfelderComponent implements AfterViewInit {

  @ViewChild('container', { read: ViewContainerRef }) container!: ViewContainerRef;
  private berechtigungsListe = berechtigungsListe
  private feldListe = {
    bestellErinnerung: CheckboxBestellerinnerungComponent,
    zustimmung: ZusatzZustimmungComponent,
  }

  constructor() {}

  ngAfterViewInit() {
    for (let feature of Object.keys(this.berechtigungsListe)){
      console.log(feature)
      if (feature != 'default'){
        if (this.berechtigungsListe[feature].includes(environment.mandant_id)){
          this.container.createComponent(this.feldListe[feature])
        }
      }
    }
  }
}
