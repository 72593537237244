import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {Platform} from '@angular/cdk/platform';
import {MandantenService} from '../mandanten/mandanten.service';
import {FlexLinkClass} from './flexLink.class';

@Injectable()
export class FlexLinkService {

  private flexLink = null

  constructor(
    public platform: Platform,
    private router: Router,
    private mandantService: MandantenService,
    ){}

  public switchLink = new FlexLinkClass().switchListe

  public flexLinkSpeichern(){
    this.mandantService.mandant_get().subscribe(mandant => {
      if (mandant.sonderKonfiguration.FlexLink){
        this.flexLink = mandant.sonderKonfiguration.FlexLink
      }
    })
  }

  public flexLinkTesten(seite){
    if (this.flexLink && this.flexLink[seite] == 'false'){
      return false
    }else{
      return true
    }
  }

  public flexLinkPruefen(seite){
    if (this.flexLink && this.flexLink[seite]){
      window.open(this.flexLink[seite], '_blank')
    }else{
      this.router.navigate(['/rechtliches'], {queryParams: {id: seite}})
    }
  }

  //TODO Technische Schuld für FAQ
  public flexLinkPruefenFAQMobil(seite){
    if (this.flexLink && this.flexLink[seite] && this.flexLink[seite] != 'false'){
      window.open(this.flexLink[seite], '_blank')
    }else{
      this.router.navigate(['/faq'], {queryParams: {id: seite}})
    }
  }
}
