import {ViewContainerRef} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {
  EinrichtungsSelektionComponent
} from '../../submodul/obs.shared/fragmente/formular-felder/einrichtungsSelektion/einrichtungsSelektion.component';

export class RegistrierungsEinrichtungRegel {

  private einrichtungen = []

  constructor(
    private viewContainerRef: ViewContainerRef,
    private mandant
  ){

  }

  public init(componentRefStack, formGroup: FormGroup, feldConfig){
    componentRefStack['einrichtung_nameEinrichtung'].destroy()
    if (componentRefStack['einrichtung_nameGruppe']){
      componentRefStack['einrichtung_nameGruppe'].destroy()
    }
    let einrichtung: any
    for (einrichtung of Object.entries(this.mandant.sonderKonfiguration['API_1_5']['einricht2GrpMap'])) {
      this.einrichtungen.push(einrichtung[0])
    }
    if (this.mandant.sonderKonfiguration['API_1_5']['einricht2GrpMap'][einrichtung[0]]){
      //feldConfig['einrichtung_nameEinrichtung']['options'] = this.einrichtungen
      componentRefStack['einrichtung_nameEinrichtung'] = this.viewContainerRef.createComponent(EinrichtungsSelektionComponent)
      componentRefStack['einrichtung_nameEinrichtung'].instance.mandant = this.mandant
      componentRefStack['einrichtung_nameEinrichtung'].instance.formGroup = formGroup
      componentRefStack['einrichtung_nameEinrichtung'].instance.feldConfig = {
        'einrichtung_nameEinrichtung': feldConfig['einrichtung_nameEinrichtung'],
        'einrichtung_nameGruppe': feldConfig['einrichtung_nameGruppe']
      }
    }
  }
}
