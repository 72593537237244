import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {environment} from '../../../../../../environments/environment';

@Component({
  selector: 'zusatzZustimmung',
  templateUrl: './zusatzZustimmung.component.html',
  styleUrls: ['./zusatzZustimmung.component.css']
})
export class ZusatzZustimmungComponent implements OnInit {
  @Input() tabIndex
  @HostBinding('className') componentClass: string;
  public checked1 = window.localStorage.getItem('r_erweiterteZustimmung1') == 'true'
  public checked2 = window.localStorage.getItem('r_erweiterteZustimmung2') == 'true'

  constructor() { }

  ngOnInit(): void {
    if (!window.localStorage.getItem('r_erweiterteZustimmung1')){
      window.localStorage.setItem('r_erweiterteZustimmung1', 'true');
    }
    if (!window.localStorage.getItem('r_erweiterteZustimmung2')){
      window.localStorage.setItem('r_erweiterteZustimmung2', 'false');
    }
  }

  ngAfterViewInit(): void {}

  public erweiterteZustimmungSchreiben(event) {
    if (event.target.checked) {
      window.localStorage.setItem('r_erweiterteZustimmung2', 'true');
    }else{
      window.localStorage.setItem('r_erweiterteZustimmung2', 'false');
    }
  }

  protected readonly environment = environment;
}
