import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppStateService } from 'services/app/app-state.service';
import { map } from 'rxjs/operators';
import { DialogService } from 'services/prozesse/dialog.service';
import { MandantenService } from 'services/mandanten/mandanten.service';
import { SpeiseplanService } from 'submodul/obs.speiseplan/services/speiseplan.service';
import {MitteilungsService} from '../../../services/prozesse/mitteilung.service';
import {zip} from 'rxjs';

@Component({
  selector: 'mobil-speiseplan-mandant',
  templateUrl: './mobil-speiseplan-mandant.component.html',
  styleUrls: ['./../../../submodul/obs.speiseplan/einzelbesteller/speiseplanDarstellung/speiseplanDarstellungWBA.css'],
})
export class MobilSpeiseplanMandantComponent implements OnInit {

  @ViewChild('speiseplan_kalender') speiseplan_kalender
  @ViewChild('speiseplan_details') speiseplan_details
  public speiseplan_id
  public apiZeitraum
  public mandant = null
  public kunde = null
  public speiseplan: any
  public modus
  public darstellung = 2

  constructor(
    private dialog_service: DialogService,
    private active_route: ActivatedRoute,
    private mandant_service: MandantenService,
    private speiseplan_service: SpeiseplanService,
    private state_service: AppStateService,
    private mitteilungsService: MitteilungsService
  ) {
    this.dialog_service.app_menu = true

    this.state_service.set_page_name('Speiseplan');
    this.state_service.set_page_navigation('normal');
  }

  ngOnInit() {

    zip(
      this.speiseplan_service.speiseplan_zeitraum(),
      this.mandant_service.mandant_get()
    ).subscribe(apiResponse => {
      this.apiZeitraum = apiResponse[0]
      this.mandant = apiResponse[1]
      this.active_route.params.subscribe((params) => {
        this.speiseplan_id = params['id']
        this.state_service.set_seitenname(`speiseplan-mandant/${this.speiseplan_id}`)
        let splanConfig = Object.values(this.mandant.speisePlanIds).filter(speiseplanConfig => {
          return speiseplanConfig['splanId'] == this.speiseplan_id
        })
        this.state_service.set_page_name(splanConfig[0]['bezeichnung'])
      })
    })
  }

  speiseplan_laden(zeitraum) {
    this.modus = this.speiseplan_kalender.modus
    this.speiseplan_service.speiseplan_mandant(this.speiseplan_id, zeitraum.start, zeitraum.ende)
      .pipe(map((response) => { return <any>response }))
      .subscribe((response) => {
        this.speiseplan = {
          mandant: this.mandant,
          bestellschluss: response.bestellschlussMsgs,
          menu: response.speiseplanTage,
          menutexte: response.menuetexte
        }
      },
        (error) => { this.mitteilungsService.set_snackbar(error.error.content.msgId) },)
  }
}

