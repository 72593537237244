import {Component, Input, AfterViewInit} from '@angular/core';
import {SpeiseplanSondertagComponent} from '../speiseplanZustellpause/speiseplanSondertag.component';
import {MatDialog} from '@angular/material/dialog';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';

@Component({
  selector: 'speiseplanTagLabel',
  templateUrl: './speiseplanTagLabel.component.html',
  styleUrls: ['./speiseplanTagLabel.component.css'],
})
export class SpeiseplanTagLabelComponent implements AfterViewInit {

  @Input('tag') tag
  @Input('speiseplan') speiseplan
  @Input('darstellung') darstellung
  @Input('modus') modus
  @Input('kundeId') kundeId

  public feiertag
  public zustellPause


  constructor(
    public dialog: MatDialog,
    private router: Router,
  ) {
  }

  public menueAnzahl
  public mobil = false

  ngAfterViewInit() {

    const speisePlanTag = this.speiseplan?.kunden?.[this.kundeId]?.speisePlanTage?.[this.tag.key];
    if (speisePlanTag) {
      this.tag.value.zustellPause ? this.zustellPause = this.tag.value.zustellPause : this.zustellPause = speisePlanTag.zustellPause;
      this.tag.value.feiertag ? this.feiertag = this.tag.value.feiertag : this.feiertag = speisePlanTag.feiertag;
    } else {
      this.zustellPause = this.tag.value.zustellPause
      this.feiertag = this.tag.value.feiertag
    }

    this.mobil = environment.type != 'web'
    if (this.router.url == '/warenkorb'){
      this.menueAnzahl = Object.keys(this.tag.value.bestellMenues).length
    } else {
      this.menueAnzahl = Object.keys(this.tag.value.tagesMenues).length
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(SpeiseplanSondertagComponent, {})
    dialogRef.componentInstance.feiertag = this.feiertag
    dialogRef.componentInstance.zustellpause = this.zustellPause
  }
}
