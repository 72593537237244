<div id="bestellhistorie">
  <strong>Bestellzeitraum wählen</strong>
  <mat-calendar
    startView="month"
    [dateClass]="markiereBestellungen()"
    [minDate]="apiZeitraum.von"
    [maxDate]="apiZeitraum.bis"
    [selected]="dateRange"
    (selectedChange)="selectedChange($event)"
  ></mat-calendar>
  <div id="zeitraumShorttags">
    <button mat-button class="bestellhistorie_btn" (click)="aktuelle_woche()">Aktuelle Woche</button>
    <button mat-button class="bestellhistorie_btn" (click)="aktueller_monat()">Aktueller Monat</button>
  </div>
  <div id="bestellhistoriePickerOptions">
    <button mat-button class="bad" mat-dialog-close>Abbrechen</button>
    <button mat-button class="good" (click)="speichern()">Übernehmen</button>
  </div>
</div>
