import {AfterViewInit, Component, OnDestroy, OnInit, signal, inject} from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {MatCalendar} from '@angular/material/datepicker';
import {Subject} from 'rxjs';
import {startWith, takeUntil} from 'rxjs/operators';
// import {SpeiseplanSammelbestellerService} from '../../../../services/speiseplan-sammebesteller.service';
// import {SpeiseplanService} from '../../../../services/speiseplan.service';

@Component({
	selector: 'kalenderHeader',
	templateUrl: './kalenderHeader.component.html',
	styleUrls: ['./kalenderHeader.component.css']
})
export class KalenderHeaderComponent<D> implements OnInit, AfterViewInit, OnDestroy {
  private _calendar = inject<MatCalendar<D>>(MatCalendar);
  private _dateAdapter = inject<DateAdapter<D>>(DateAdapter);
  private _dateFormats = inject(MAT_DATE_FORMATS);

  private _destroyed = new Subject<void>();

  readonly periodLabel = signal('');


  constructor(
    // public speiseplanSammelbestellerService: SpeiseplanSammelbestellerService,
    // private speiseplanService: SpeiseplanService,
  ) {
    this._calendar.stateChanges.pipe(startWith(null), takeUntil(this._destroyed)).subscribe(() => {
      this.periodLabel.set(
        this._dateAdapter
          .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
          .toLocaleUpperCase(),
      );
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  previousClicked(mode: 'month' | 'year') {
    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
  }

  nextClicked(mode: 'month' | 'year') {
    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
  }

  // public erneuern(){
  //   this.speiseplanService.speiseplan_zeitraum().subscribe(zeitraum => {
  //     document.getElementById('kalenderReset').classList.add('rotate-center')
  //     let filter:any = this.speiseplanSammelbestellerService.sammelbestellerFilter.getValue()
  //     filter.apiZeitraum = zeitraum
  //     this.speiseplanSammelbestellerService.filterAktualisieren(filter)
  //     setTimeout(() => {
  //       document.getElementById('kalenderReset').classList.remove('rotate-center')
  //     }, 1000)
  //   })
  // }
}
