import {AfterViewInit, Directive, ElementRef, Input, Renderer2} from '@angular/core';

@Directive({
  selector: '[appRegistrierungZusammenfassungRenderer]'
})
export class RegistrierungZusammenfassungRendererDirective implements AfterViewInit{

  @Input() formData
  @Input() formConfig

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
  ) { }

  async ngAfterViewInit() {
    for (let feldName in this.formConfig){
      if (this.formConfig[feldName].active){
        const div = this.renderer.createElement('div')
        if (this.formConfig[feldName].type == 'checkbox' || this.formConfig[feldName].type == 'textarea'){
          this.renderer.addClass(div, 'registrierungZusammenfassungItemVertical')
        }else{
          this.renderer.addClass(div, 'registrierungZusammenfassungItem')
        }
        this.renderer.addClass(div, feldName)
        const label = this.renderer.createElement('span')
        if (this.formConfig[feldName].name !== 'freiText'){
          this.renderer.setProperty(label, 'innerHTML', this.formConfig[feldName].text)
        }else{
          this.renderer.setProperty(label, 'innerHTML', 'Freitext')
        }
        this.renderer.appendChild(div, label)

        const value = this.renderer.createElement('span')
        this.renderer.addClass(value, 'zusammenFassungValue')
        if (this.formData[feldName] != undefined){
          if (this.formData[feldName] !== ''){
            if (this.formConfig[feldName].type == 'checkbox'){
              if (this.formData[feldName] == 'true' || this.formData[feldName] == '1'){
                this.renderer.setProperty(value, 'innerHTML', 'Ja')
              }else{
                this.renderer.setProperty(value, 'innerHTML', 'Nein')
              }
            }else if (this.formConfig[feldName].type == 'passwort') {
              this.renderer.setProperty(value, 'innerHTML', '******')
            }else if (this.formConfig[feldName].type == 'date') {
              this.renderer.setProperty(value, 'innerHTML', new Date(this.formData[feldName]).toLocaleDateString('de-DE'))
            }else{
              this.renderer.setProperty(value, 'innerHTML', this.formData[feldName])
            }
          } else {
            this.renderer.setProperty(value, 'innerHTML', 'Keine Angabe')
          }
        } else {
            this.renderer.setProperty(value, 'innerHTML', 'Keine Angabe')
        }
        this.renderer.appendChild(div, value)

        this.renderer.appendChild(this.el.nativeElement, div)
      }
    }
  }
}
