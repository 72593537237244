<div id="menuVorauswahlSeitenleistenContainer">
  <div id="menuVorauswahlSeitenleistenOptionen">
    <button (click)="speichereVorauswahl()" >Änderungen übernehmen</button>
    <button (click)="verwerfeVorauswahl()">Änderungen verwerfen</button>
  </div>

  <div id="vorauswahlSchluss">
    <strong>Vorauswahlschluss</strong>
    <span class="bestellzeiten"><img src="assets/icons/bestellschluss/icon_bestellschluss.webp" />{{ bestellzeiten(tag.vorauswahlSchluss * 1000) }}</span>
  </div>

  <div id="menuVorauswahlSeitenleistenInhalt">
    <div id="speiseplanDetail" *ngIf="menu && mandant">
      <div class="speiseplan-details-titel">
        <strong>{{ menu.bezeichnung }}</strong>
        <strong *ngIf="mandant.preisPositionInSplan !== 'none'">{{ menu.preis | euroPrice }}</strong>
      </div>
      <div id="speiseplanDetailsContent">
        <div class="speiseplan-details-beschreibung details-abschnitt" [innerHTML]="menu.menueText | bbCode"></div>
        <div class="speiseplan-details-beschreibung details-abschnitt" *ngFor="let test of Object.values(neben)" [innerHTML]="test.menueText | bbCode"></div>
      </div>
      <div id="seitenleisteGlossar">
        <speiseplan-glossar [mandant]="mandant"></speiseplan-glossar>
      </div>
    </div>
  </div>
</div>
