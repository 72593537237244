import {Component, OnInit } from '@angular/core';
import { AppStateService } from 'services/app/app-state.service';
import { KundeService } from 'services/kunden/kunde.service';
import {BenachrichtigungService} from '../../../services/prozesse/benachrichtigung.service';

@Component({
  selector: 'app-messages',
  templateUrl: './mobil-messages.component.html',
  styleUrls: ['./mobil-messages.component.css']
})
export class MobilMessageComponent implements OnInit {

  public messages
  public info
  public flag

  constructor(
    private app_state_service: AppStateService,
    public kunden_service: KundeService,
    private benachrichtigung_service: BenachrichtigungService,
  ) {
    this.app_state_service.set_page_name('Mitteilungen');
    this.app_state_service.set_seitenname('benachrichtigungen')
    this.app_state_service.set_page_navigation('normal');
  }

  ngOnInit() {
    this.benachrichtigung_service.benachrichtigung_stummschalten()
    this.kunden_service._kunde_get().subscribe((kunde) => {
      this.info = kunde.einrichtung.info
    })
  }
}
